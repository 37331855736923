import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ForgotPassword, Home, Login, NotFound, PseudoGroups, Regions, Smelters, SignUp, Users } from 'screens';
import { AppliedRoute } from 'components';

export default ({ childProps }) => (
	<Switch>
		<AppliedRoute path="/" exact component={Login} props={childProps} noTemplate={true} insecure={true} />
		<AppliedRoute
			path="/forgotPassword"
			exact
			component={ForgotPassword}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>
		<AppliedRoute path="/home" exact component={Home} props={childProps} />
		<AppliedRoute path="/pseudogroup" exact component={PseudoGroups} props={childProps} />
		<AppliedRoute path="/region" exact component={Regions} props={childProps} />
		<AppliedRoute path="/signUp" exact component={SignUp} props={childProps} noTemplate={true} insecure={true} />
		<AppliedRoute path="/smelter" exact component={Smelters} props={childProps} />
		<AppliedRoute path="/users" exact component={Users} props={childProps} />
		<Route component={NotFound} />
	</Switch>
);
