import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';

import { fetchRegions, fetchSmelters, fetchPseudoGroups, fetchUsersStaging, fetchUsersProduction } from 'actions';
import { Crumbs } from 'containers';

import './Home.scss';
import savantAdmin from 'images/savant-admin.png';

class Home extends Component {
	state = {
		helpModal: false
	};

	componentDidMount() {
		this.props.fetchRegions();
		this.props.fetchSmelters();
		this.props.fetchPseudoGroups();
		this.props.fetchUsersStaging();
		this.props.fetchUsersProduction();
	}

	toggleHelp = () => {
		this.setState((prevState) => ({
			helpModal: !prevState.helpModal
		}));
	};

	buildModals() {
		const { helpModal } = this.state;

		return (
			<Fragment>
				<Modal isOpen={helpModal} toggle={this.toggleHelp}>
					<ModalHeader toggle={this.toggleHelp}>
						<i className="fas fa-medkit mr-3" />Help me
					</ModalHeader>
					<ModalBody>
						<p>
							The Savant Admin platform is being actively developed and it should not be considered ready
							for production use until advised otherwise.
						</p>
						<p className="text-center">
							<i className="far fa-grin-wink fa-lg mr-2" /> Please come back soon!
						</p>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" size="sm" onClick={this.toggleHelp}>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}

	render() {
		const { regions = [], smelters = [], pseudoGroups = [], usersStaging = [], usersProduction = [] } = this.props;

		return (
			<DocumentTitle title="Savant Admin | Dashboard">
				<Container fluid>
					<Crumbs />
					<Row noGutters>
						<Col xs="4" sm="5" md="5" lg="7" xl="8" className="pr-3">
							<Card>
								<CardBody className="p-2">
									<p>
										The <strong>Savant Admin Platform</strong> provides an interface through which
										Savant content can be managed and maintained. Most of the content here is within
										the <strong>Staging</strong> database, where we prepare it for cloning to{' '}
										<strong>Production</strong>. However, it is possible to administer Users on both
										Staging and Production.
									</p>
									<p className="mb-0">
										You may wish to review{' '}
										<NavLink to="/smelter">
											<strong>Smelters</strong>
										</NavLink>, or{' '}
										<NavLink to="/pseudogroup">
											<strong>PseudoGroups</strong>
										</NavLink>, or{' '}
										<NavLink to="/region">
											<strong>Regions</strong>
										</NavLink>{' '}
										or{' '}
										<NavLink to="/users">
											<strong>Users</strong>
										</NavLink>{' '}
										using the menu options to the left.
									</p>
								</CardBody>
							</Card>
							<Card className="mt-3">
								<CardHeader className="position-relative">
									<h6 className="mb-0">Data structure</h6>
								</CardHeader>
								<CardBody className="p-2">
									<img src={savantAdmin} alt="Data structure" className="float-right ml-3 mb-3" />

									<ol className="pl-4 pt-2">
										<li className="mb-2">
											<strong>Regions</strong> are objects with basic display properties - they
											can be created and modified,{' '}
											<span className="text-danger font-weight-bold">but not deleted</span>.
										</li>
										<li className="mb-2">
											<strong>Smelters</strong> are objects with basic display properties which
											belong within <strong>Regions</strong> and which might be referenced by
											multiple <strong>Captures</strong>. They can be created, modified and
											deleted.
										</li>
										<li className="mb-2">
											There is no <strong>Capture</strong> management here - it is assumed that{' '}
											<strong>Captures</strong> are provided programmatically.
										</li>
										<li className="mb-2">
											<strong>Pseudo Groups</strong> are objects with both display properties and
											programmatic properties, which provide display grouping and tie together{' '}
											<strong>Smelters</strong> and <strong>Security Groups</strong> to which{' '}
											<strong>Users</strong> belong via Cognito <strong>Groups</strong>. They can
											be created, modified and deleted.
										</li>
										<li className="mb-2">
											Selecting the <strong>Smelters</strong> which belong to a{' '}
											<strong>Pseudo Group</strong> is done from <strong>Pseudo Group</strong>{' '}
											admin.
										</li>
										<li className="mb-2">
											Creating, modifying and deleting the <strong>Security Groups</strong> for a{' '}
											<strong>Pseudo Group</strong> is done via the{' '}
											<strong>Security groups</strong> management action from within the{' '}
											<strong>Pseudo Group</strong> listing.
										</li>
										<li>
											<strong>Smelters</strong> and <strong>Pseudo Groups</strong> both have a
											property that indicates the type of product to which they belong. i.e.
											Copper / Nickel.
										</li>
									</ol>
								</CardBody>
							</Card>
						</Col>
						<Col xs="8" sm="7" md="7" lg="5" xl="4">
							<Card className="mb-3">
								<CardHeader className="position-relative">
									<h6 className="mb-0" style={{ marginTop: '4px' }}>
										System status
									</h6>
									<Button
										color="secondary"
										size="sm"
										className="position-absolute"
										style={{ top: '8px', right: '8px' }}
										onClick={(e) => this.toggleHelp()}
									>
										<i className="fas fa-medkit mr-2" /> Help me
									</Button>
								</CardHeader>
								<CardBody className="p-2">
									<p className="ml-2 mb-0 small">
										<i className="fas fa-heartbeat mr-2 fa-fw" />Health:{' '}
										<span className="text-success font-weight-bold">Good</span>
										<br />
										<i className="fas fa-fire mr-2 fa-fw" />Smelters:{' '}
										<span className="font-weight-bold">{smelters.length}</span>
										<br />
										<i className="fas fa-layer-group mr-2 fa-fw" />PseudoGroups:{' '}
										<span className="font-weight-bold">{pseudoGroups.length}</span>
										<br />
										<i className="fas fa-globe-americas mr-2 fa-fw" />Regions:{' '}
										<span className="font-weight-bold">{regions.length}</span>
										<br />
										<i className="fas fa-user-friends mr-2 fa-fw" />Users (staging):{' '}
										<span className="font-weight-bold">{usersStaging.length}</span>
										<br />
										<i className="fas fa-user-friends mr-2 fa-fw" />Users (production):{' '}
										<span className="font-weight-bold">{usersProduction.length}</span>
									</p>
								</CardBody>
							</Card>
						</Col>
					</Row>
					{this.buildModals()}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ regions, smelters, pseudoGroups, usersStaging, usersProduction }) => {
	return {
		regions, // redux
		smelters, // redux
		pseudoGroups, // redux
		usersStaging, // redux
		usersProduction // redux
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchRegions: () => dispatch(fetchRegions()),
	fetchSmelters: () => dispatch(fetchSmelters()),
	fetchPseudoGroups: () => dispatch(fetchPseudoGroups()),
	fetchUsersStaging: () => dispatch(fetchUsersStaging()),
	fetchUsersProduction: () => dispatch(fetchUsersProduction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
