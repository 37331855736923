import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import pseudoGroupsReducer from './pseudoGroupsReducer';
import pseudoGroupSecurityGroupsReducer from './pseudoGroupSecurityGroupsReducer';
import regionsReducer from './regionsReducer';
import smeltersReducer from './smeltersReducer';
import { usersStagingReducer, usersProductionReducer, userProcessReducer } from './usersReducers';

export default combineReducers({
	toastr: toastrReducer,
	pseudoGroups: pseudoGroupsReducer,
	pseudoGroupSecurityGroups: pseudoGroupSecurityGroupsReducer,
	regions: regionsReducer,
	smelters: smeltersReducer,
	usersStaging: usersStagingReducer,
	usersProduction: usersProductionReducer,
	userProcess: userProcessReducer
});
