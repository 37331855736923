import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import DocumentTitle from 'react-document-title';
import {
	Button,
	Col,
	Container,
	CustomInput,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormFeedback,
	Form,
	FormGroup,
	FormText,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
	UncontrolledDropdown
} from 'reactstrap';
import DatePicker from 'reactstrap-date-picker';

import { fetchRegions, fetchSmelters, createSmelter, updateSmelter, deleteSmelter } from 'actions';
import config from 'config';

import { Crumbs } from 'containers';

import './Smelters.scss';

class Smelters extends Component {
	state = {
		createModal: false,
		updateModal: false,
		updateSmelter: {},
		deleteModal: false,
		deleteSmelter: {},
		typeFilter: '',
		nameFilter: ''
	};

	componentDidMount() {
		this.props.fetchRegions();
		this.props.fetchSmelters();
	}

	onReloadList = () => {
		this.props.fetchSmelters();
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	toggleCreate = () => {
		this.setState((prevState) => ({
			createModal: !prevState.createModal
		}));
	};

	onCreateAction = async ({
		values: {
			createSmelterName,
			createSmelterLat,
			createSmelterLon,
			createSmelterRegionId,
			createSmelterCapacity,
			createSmelterCountry,
			createSmelterCompany,
			createSmelterType,
			createSmelterSandbox,
			createSmelterAoi,
			createSmelterStartDate,
			createSmelterEndDate
		},
		setSubmitting
	}) => {
		// Insert the new smelter into the db
		await this.props.createSmelter({
			name: createSmelterName.trim(),
			lat: Number.parseFloat(createSmelterLat),
			lon: Number.parseFloat(createSmelterLon),
			region_id: parseInt(createSmelterRegionId),
			capacity: parseInt(createSmelterCapacity),
			country: createSmelterCountry.trim(),
			company: createSmelterCompany.trim(),
			type: createSmelterType.trim(),
			sandbox: parseInt(createSmelterSandbox),
			aoi: createSmelterAoi.trim(),
			start_date: createSmelterStartDate.trim(),
			end_date: createSmelterEndDate.trim()
		});

		setSubmitting(false);

		this.setState({
			createModal: false
		});
	};

	toggleUpdate = () => {
		this.setState((prevState) => ({
			updateModal: !prevState.updateModal
		}));
	};

	onUpdate = ({ smelter }) => {
		this.setState({
			updateSmelter: smelter,
			updateModal: true
		});
	};

	onUpdateAction = async ({
		values: {
			updateSmelterId,
			updateSmelterName,
			updateSmelterLat,
			updateSmelterLon,
			updateSmelterRegionId,
			updateSmelterCapacity,
			updateSmelterCountry,
			updateSmelterCompany,
			updateSmelterType,
			updateSmelterSandbox,
			updateSmelterAoi,
			updateSmelterStartDate,
			updateSmelterEndDate
		},
		setSubmitting
	}) => {
		// Update the smelter in the db
		await this.props.updateSmelter({
			id: updateSmelterId,
			name: updateSmelterName.trim(),
			lat: Number.parseFloat(updateSmelterLat),
			lon: Number.parseFloat(updateSmelterLon),
			region_id: parseInt(updateSmelterRegionId),
			capacity: parseInt(updateSmelterCapacity),
			country: updateSmelterCountry.trim(),
			company: updateSmelterCompany.trim(),
			type: updateSmelterType.trim(),
			sandbox: parseInt(updateSmelterSandbox),
			aoi: updateSmelterAoi,
			start_date: updateSmelterStartDate,
			end_date: updateSmelterEndDate
		});

		setSubmitting(false);

		this.setState({
			updateModal: false,
			updateSmelter: {}
		});
	};

	toggleDelete = () => {
		this.setState((prevState) => ({
			deleteModal: !prevState.deleteModal
		}));
	};

	onDelete = ({ smelter }) => {
		this.setState({
			deleteSmelter: smelter,
			deleteModal: true
		});
	};

	onDeleteAction = async () => {
		const { deleteSmelter: { id } } = this.state;

		// Delete the smelter in the db
		await this.props.deleteSmelter({
			id
		});

		this.setState({
			deleteModal: false,
			deleteSmelter: {}
		});
	};

	buildResultsTable() {
		let { smelters = [], regions = [] } = this.props;
		const { typeFilter, nameFilter } = this.state;
		const regionLookup = Object.fromEntries(regions.map((r) => [ `r${r.id}`, r.name ]));

		if (nameFilter !== '') {
			smelters = smelters.filter(({ name }) => {
				return name.toLowerCase().includes(nameFilter.toLowerCase());
			});
		}
		if (typeFilter !== '') {
			smelters = smelters.filter(({ type }) => {
				return type === typeFilter;
			});
		}

		return (
			<Row noGutters>
				<Col xs="12">
					<Table>
						<thead>
							<tr>
								<th style={{ width: '60px' }}>#</th>
								<th style={{ width: '70px' }}>ID</th>
								<th>Region</th>
								<th>Type</th>
								<th colSpan="2">
									Smelter name
									<div
										className="position-absolute align-bottom"
										style={{ right: '12px', top: '10px' }}
									>
										<CustomInput
											id="nameFilter"
											name="nameFilter"
											type="text"
											className="mr-2 name-filter"
											onChange={(event) => this.handleChange(event)}
											defaultValue={nameFilter}
											placeholder="Name search"
											bsSize="sm"
											inline
										/>
										<CustomInput
											id="typeFilter"
											name="typeFilter"
											type="select"
											className="mr-2 facet-select"
											onChange={(event) => this.handleChange(event)}
											defaultValue={typeFilter}
											bsSize="sm"
											inline
										>
											<option value="">Any type</option>
											{Object.entries(config.types).map(([ key, value ], idx) => (
												<option value={key} key={`type${idx}`}>
													{value}
												</option>
											))}
										</CustomInput>
										<Button size="sm" color="success" className="mr-2" onClick={this.toggleCreate}>
											<i className="fas fa-plus mr-2" />Add smelter
										</Button>
										<Button size="sm" outline color="secondary" onClick={this.onReloadList}>
											<i className="fas fa-sync-alt" />
											<span className="sr-only">Refresh</span>
										</Button>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{smelters.map((smelter, ind) => {
								const { id, name, type, region_id } = smelter;
								const dspInd = ind + 1;
								return (
									<tr key={`region${dspInd}`}>
										<th scope="row" style={{ width: '60px' }}>
											{dspInd}
										</th>
										<td style={{ width: '70px' }}>{id}</td>
										<td style={{ width: '200px' }}>{regionLookup[`r${region_id}`]}</td>
										<td style={{ width: '90px' }}>{config.types[type]}</td>
										<td>{name}</td>
										<td style={{ width: '45px' }}>
											<UncontrolledDropdown size="sm">
												<DropdownToggle className="pointered btn-light">
													<i className="fas fa-bars" />
													<span className="sr-only">Actions</span>
												</DropdownToggle>
												<DropdownMenu>
													<DropdownItem
														className="pointered"
														onClick={(e) => this.onUpdate({ smelter }, e)}
													>
														<i className="fas fa-edit fa-fw mr-2" />Update
													</DropdownItem>
													<DropdownItem
														className="pointered"
														onClick={(e) => this.onDelete({ smelter }, e)}
													>
														<i className="fas fa-trash-alt fa-fw mr-2" />Delete
													</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		);
	}

	buildModals() {
		const { regions } = this.props;
		const { createModal, updateModal, updateSmelter, deleteModal, deleteSmelter } = this.state;

		return (
			<Fragment>
				<Modal isOpen={createModal} toggle={this.toggleCreate}>
					<Formik
						initialValues={{
							createSmelterName: '',
							createSmelterLat: '',
							createSmelterLon: '',
							createSmelterRegionId: '',
							createSmelterCapacity: '',
							createSmelterCountry: '',
							createSmelterCompany: '',
							createSmelterType: '',
							createSmelterSandbox: '',
							createSmelterAoi: '',
							createSmelterStartDate: '',
							createSmelterEndDate: ''
						}}
						validate={(values) => {
							const errors = {};
							if (values.createSmelterName.trim().length === 0) {
								errors.createSmelterName = 'A name is required';
							} else if (values.createSmelterName.trim().length > 80) {
								errors.createSmelterName = 'Name should be no more than 80 characters';
							}
							if (values.createSmelterLat.trim().length === 0 || isNaN(values.createSmelterLat)) {
								errors.createSmelterLat = 'A number is required';
							}
							if (values.createSmelterLon.trim().length === 0 || isNaN(values.createSmelterLon)) {
								errors.createSmelterLon = 'A number is required';
							}
							if (
								values.createSmelterRegionId.trim().length === 0 ||
								isNaN(values.createSmelterRegionId)
							) {
								errors.createSmelterRegionId = 'Please choose a region';
							}
							if (
								values.createSmelterCapacity.trim().length === 0 ||
								isNaN(values.createSmelterCapacity)
							) {
								errors.createSmelterCapacity = 'A number is required';
							}
							if (values.createSmelterCountry.trim().length === 0) {
								errors.createSmelterCountry = 'A country is required';
							} else if (values.createSmelterCountry.trim().length > 100) {
								errors.createSmelterCountry = 'Country should be no more than 100 characters';
							}
							if (values.createSmelterCompany.trim().length === 0) {
								errors.createSmelterCompany = 'Company details are required';
							} else if (values.createSmelterCompany.trim().length > 256) {
								errors.createSmelterCompany = 'Company details should be no more than 256 characters';
							}
							if (values.createSmelterType.trim().length === 0) {
								errors.createSmelterType = 'A type is required';
							}
							if (values.createSmelterSandbox.trim().length === 0 || isNaN(values.createSmelterSandbox)) {
								errors.createSmelterSandbox = 'A number is required';
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onCreateAction({ values, setSubmitting });
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							setFieldValue,
							handleSubmit,
							isSubmitting
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalHeader toggle={this.toggleCreate}>
									<i className="fas fa-plus mr-3" />Create a new smelter
								</ModalHeader>
								<ModalBody>
									<FormGroup>
										<Label for="createSmelterName">Smelter name</Label>
										<Input
											tag={Field}
											id="createSmelterName"
											placeholder="Kalgoorlie"
											value={values.createSmelterName}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={errors.createSmelterName && touched.createSmelterName}
											bsSize="sm"
										/>
										<FormText>Smelter name should be no more than 80 characters</FormText>
										<FormFeedback>{errors.createSmelterName}</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="createSmelterCompany">Company details</Label>
										<Input
											id="createSmelterCompany"
											placeholder="BHP Billiton Nickel West"
											value={values.createSmelterCompany}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={errors.createSmelterCompany && touched.createSmelterCompany}
											bsSize="sm"
											type="textarea"
											rows="3"
										/>
										<FormText>Company details should be no more than 256 characters</FormText>
										<FormFeedback>{errors.createSmelterCompany}</FormFeedback>
									</FormGroup>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="createSmelterLat">Latitude</Label>
												<Input
													tag={Field}
													id="createSmelterLat"
													placeholder="-30.873338"
													value={values.createSmelterLat}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.createSmelterLat && touched.createSmelterLat}
													bsSize="sm"
												/>
												<FormText>Latitude should be numeric</FormText>
												<FormFeedback>{errors.createSmelterLat}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="createSmelterLon">Longitude</Label>
												<Input
													tag={Field}
													id="createSmelterLon"
													placeholder="121.484254"
													value={values.createSmelterLon}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.createSmelterLon && touched.createSmelterLon}
													bsSize="sm"
												/>
												<FormText>Longitude should be numeric</FormText>
												<FormFeedback>{errors.createSmelterLon}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="createSmelterRegionId">Region</Label>
												<CustomInput
													id="createSmelterRegionId"
													type="select"
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.createSmelterRegionId}
													invalid={
														errors.createSmelterRegionId && touched.createSmelterRegionId
													}
													bsSize="sm"
												>
													<option value="">Please select a region</option>
													{regions
														.filter(({ name }) => name !== 'All')
														.map(({ id, name }, idx) => (
															<option value={id} key={`region${idx}`}>
																{name}
															</option>
														))}
												</CustomInput>
												<FormText>A region is required</FormText>
												<FormFeedback>{errors.createSmelterRegionId}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="createSmelterCountry">Country</Label>
												<CustomInput
													id="createSmelterCountry"
													type="select"
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.createSmelterCountry}
													invalid={
														errors.createSmelterCountry && touched.createSmelterCountry
													}
													bsSize="sm"
												>
													<option value="">Please select a country</option>
													{config.countries.map((name, idx) => (
														<option value={name} key={`country${idx}`}>
															{name}
														</option>
													))}
												</CustomInput>
												<FormText>A country is required</FormText>
												<FormFeedback>{errors.createSmelterCountry}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="createSmelterType">Type</Label>
												<CustomInput
													id="createSmelterType"
													type="select"
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.createSmelterType}
													invalid={errors.createSmelterType && touched.createSmelterType}
													bsSize="sm"
												>
													<option value="">Please select a type</option>
													{Object.entries(config.types).map(([ key, value ], idx) => (
														<option value={key} key={`type${idx}`}>
															{value}
														</option>
													))}
												</CustomInput>
												<FormText>A type is required</FormText>
												<FormFeedback>{errors.createSmelterType}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="createSmelterCapacity">Capacity (kilotonnes / anno)</Label>
												<Input
													tag={Field}
													id="createSmelterCapacity"
													placeholder="72"
													value={values.createSmelterCapacity}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.createSmelterCapacity && touched.createSmelterCapacity
													}
													bsSize="sm"
												/>
												<FormText>Capacity should be numeric</FormText>
												<FormFeedback>{errors.createSmelterCapacity}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="createSmelterSandbox">Sandbox</Label>
												<CustomInput
													id="createSmelterSandbox"
													type="select"
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.createSmelterSandbox}
													invalid={
														errors.createSmelterSandbox && touched.createSmelterSandbox
													}
													bsSize="sm"
												>
													<option value="">Please select a sandbox type</option>
													{config.sandbox.map(({ val, name }, idx) => (
														<option value={val} key={`sandbox${idx}`}>
															{name}
														</option>
													))}
												</CustomInput>
												<FormText>A sandbox type is required</FormText>
												<FormFeedback>{errors.createSmelterSandbox}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<FormGroup>
										<Label for="createSmelterAoi">Aoi</Label>
										<Input
											id="createSmelterAoi"
											value={values.createSmelterAoi}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={errors.createSmelterAoi && touched.createSmelterAoi}
											bsSize="sm"
											type="textarea"
											rows="5"
										/>
										<FormFeedback>{errors.createSmelterAoi}</FormFeedback>
									</FormGroup>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="createSmelterStartDate">Start Date</Label>
												<DatePicker
													id="createSmelterStartDate"
													value={values.createSmelterStartDate}
													onBlur={handleBlur}
													onChange={(v, f) => setFieldValue('createSmelterStartDate', v)}
													invalid={
														errors.createSmelterStartDate && touched.createSmelterStartDate
													}
													size="sm"
												/>
												<FormText>Enter an optional start date</FormText>
												<FormFeedback>{errors.createSmelterStartDate}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="createSmelterEndDate">End Date</Label>
												<DatePicker
													id="createSmelterEndDate"
													value={values.createSmelterEndDate}
													onBlur={handleBlur}
													onChange={(v, f) => setFieldValue('createSmelterEndDate', v)}
													invalid={
														errors.createSmelterEndDate && touched.createSmelterEndDate
													}
													size="sm"
												/>
												<FormText>Enter an optional end date</FormText>
												<FormFeedback>{errors.createSmelterEndDate}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" size="sm" type="submit" disabled={isSubmitting}>
										Create smelter
									</Button>{' '}
									<Button color="secondary" size="sm" type="button" onClick={this.toggleCreate}>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</Modal>
				<Modal isOpen={updateModal} toggle={this.toggleUpdate}>
					<Formik
						initialValues={{
							updateSmelterId: updateSmelter.id,
							updateSmelterName: updateSmelter.name,
							updateSmelterLat: updateSmelter.lat,
							updateSmelterLon: updateSmelter.lon,
							updateSmelterRegionId: updateSmelter.region_id,
							updateSmelterCapacity: updateSmelter.capacity,
							updateSmelterCountry: updateSmelter.country,
							updateSmelterCompany: updateSmelter.company,
							updateSmelterType: updateSmelter.type,
							updateSmelterSandbox: updateSmelter.sandbox,
							updateSmelterAoi: updateSmelter.aoi,
							updateSmelterStartDate: updateSmelter.start_date,
							updateSmelterEndDate: updateSmelter.end_date
						}}
						validate={(values) => {
							const errors = {};
							if (values.updateSmelterName.trim().length === 0) {
								errors.updateSmelterName = 'A name is required';
							} else if (values.updateSmelterName.trim().length > 80) {
								errors.updateSmelterName = 'Name should be no more than 80 characters';
							}
							if (
								values.updateSmelterLat.toString().trim().length === 0 ||
								isNaN(values.updateSmelterLat)
							) {
								errors.updateSmelterLat = 'A number is required';
							}
							if (
								values.updateSmelterLon.toString().trim().length === 0 ||
								isNaN(values.updateSmelterLon)
							) {
								errors.updateSmelterLon = 'A number is required';
							}
							if (
								values.updateSmelterRegionId.toString().trim().length === 0 ||
								isNaN(values.updateSmelterRegionId)
							) {
								errors.updateSmelterRegionId = 'Please choose a region';
							}
							if (
								values.updateSmelterCapacity.toString().trim().length === 0 ||
								isNaN(values.updateSmelterCapacity)
							) {
								errors.updateSmelterCapacity = 'A number is required';
							}
							if (values.updateSmelterCountry.trim().length === 0) {
								errors.updateSmelterCountry = 'A country is required';
							} else if (values.updateSmelterCountry.trim().length > 100) {
								errors.updateSmelterCountry = 'Country should be no more than 100 characters';
							}
							if (values.updateSmelterCompany.trim().length === 0) {
								errors.updateSmelterCompany = 'Company details are required';
							} else if (values.updateSmelterCompany.trim().length > 256) {
								errors.updateSmelterCompany = 'Company details should be no more than 256 characters';
							}
							if (values.updateSmelterType.trim().length === 0) {
								errors.updateSmelterType = 'A type is required';
							}
							if (
								values.updateSmelterSandbox.toString().trim().length === 0 ||
								isNaN(values.updateSmelterSandbox)
							) {
								errors.updateSmelterSandbox = 'A number is required';
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onUpdateAction({ values, setSubmitting });
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							setFieldValue,
							handleSubmit,
							isSubmitting
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalHeader toggle={this.toggleUpdate}>
									<i className="fas fa-plus mr-3" />Update smelter
								</ModalHeader>
								<ModalBody>
									<FormGroup>
										<Label for="updateSmelterName">Smelter name</Label>
										<Input
											tag={Field}
											id="updateSmelterName"
											placeholder="Kalgoorlie"
											value={values.updateSmelterName}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={errors.updateSmelterName && touched.updateSmelterName}
											bsSize="sm"
										/>
										<FormText>Smelter name should be no more than 80 characters</FormText>
										<FormFeedback>{errors.updateSmelterName}</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="updateSmelterCompany">Company details</Label>
										<Input
											id="updateSmelterCompany"
											placeholder="BHP Billiton Nickel West"
											value={values.updateSmelterCompany}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={errors.updateSmelterCompany && touched.updateSmelterCompany}
											bsSize="sm"
											type="textarea"
											rows="3"
										/>
										<FormText>Company details should be no more than 256 characters</FormText>
										<FormFeedback>{errors.updateSmelterCompany}</FormFeedback>
									</FormGroup>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="updateSmelterLat">Latitude</Label>
												<Input
													tag={Field}
													id="updateSmelterLat"
													placeholder="-30.873338"
													value={values.updateSmelterLat}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.updateSmelterLat && touched.updateSmelterLat}
													bsSize="sm"
												/>
												<FormText>Latitude should be numeric</FormText>
												<FormFeedback>{errors.updateSmelterLat}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="updateSmelterLon">Longitude</Label>
												<Input
													tag={Field}
													id="updateSmelterLon"
													placeholder="121.484254"
													value={values.updateSmelterLon}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.updateSmelterLon && touched.updateSmelterLon}
													bsSize="sm"
												/>
												<FormText>Longitude should be numeric</FormText>
												<FormFeedback>{errors.updateSmelterLon}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="updateSmelterRegionId">Region</Label>
												<CustomInput
													id="updateSmelterRegionId"
													type="select"
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.updateSmelterRegionId}
													invalid={
														errors.updateSmelterRegionId && touched.updateSmelterRegionId
													}
													bsSize="sm"
												>
													<option value="">Please select a region</option>
													{regions
														.filter(({ name }) => name !== 'All')
														.map(({ id, name }, idx) => (
															<option value={id} key={`region${idx}`}>
																{name}
															</option>
														))}
												</CustomInput>
												<FormText>A region is required</FormText>
												<FormFeedback>{errors.updateSmelterRegionId}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="updateSmelterCountry">Country</Label>
												<CustomInput
													id="updateSmelterCountry"
													type="select"
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.updateSmelterCountry}
													invalid={
														errors.updateSmelterCountry && touched.updateSmelterCountry
													}
													bsSize="sm"
												>
													<option value="">Please select a country</option>
													{config.countries.map((name, idx) => (
														<option value={name} key={`country${idx}`}>
															{name}
														</option>
													))}
												</CustomInput>
												<FormText>A country is required</FormText>
												<FormFeedback>{errors.updateSmelterCountry}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="updateSmelterType">Type</Label>
												<CustomInput
													id="updateSmelterType"
													type="select"
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.updateSmelterType}
													invalid={errors.updateSmelterType && touched.updateSmelterType}
													bsSize="sm"
												>
													<option value="">Please select a type</option>
													{Object.entries(config.types).map(([ key, value ], idx) => (
														<option value={key} key={`type${idx}`}>
															{value}
														</option>
													))}
												</CustomInput>
												<FormText>A type is required</FormText>
												<FormFeedback>{errors.updateSmelterType}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="updateSmelterCapacity">Capacity (kilotonnes / anno)</Label>
												<Input
													tag={Field}
													id="updateSmelterCapacity"
													placeholder="72"
													value={values.updateSmelterCapacity}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.updateSmelterCapacity && touched.updateSmelterCapacity
													}
													bsSize="sm"
												/>
												<FormText>Capacity should be numeric</FormText>
												<FormFeedback>{errors.updateSmelterCapacity}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="updateSmelterSandbox">Sandbox</Label>
												<CustomInput
													id="updateSmelterSandbox"
													type="select"
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.updateSmelterSandbox}
													invalid={
														errors.updateSmelterSandbox && touched.updateSmelterSandbox
													}
													bsSize="sm"
												>
													<option value="">Please select a sandbox type</option>
													{config.sandbox.map(({ val, name }, idx) => (
														<option value={val} key={`sandbox${idx}`}>
															{name}
														</option>
													))}
												</CustomInput>
												<FormText>A sandbox type is required</FormText>
												<FormFeedback>{errors.updateSmelterSandbox}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<FormGroup>
										<Label for="updateSmelterAoi">Aoi</Label>
										<Input
											id="updateSmelterAoi"
											value={values.updateSmelterAoi}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={errors.updateSmelterAoi && touched.updateSmelterAoi}
											bsSize="sm"
											type="textarea"
											rows="5"
										/>
										<FormFeedback>{errors.updateSmelterAoi}</FormFeedback>
									</FormGroup>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="updateSmelterStartDate">Start Date</Label>
												<DatePicker
													id="updateSmelterStartDate"
													value={values.updateSmelterStartDate}
													onBlur={handleBlur}
													onChange={(v, f) => setFieldValue('updateSmelterStartDate', v)}
													invalid={
														errors.updateSmelterStartDate && touched.updateSmelterStartDate
													}
													size="sm"
												/>
												<FormText>Enter an optional start date</FormText>
												<FormFeedback>{errors.updateSmelterStartDate}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="updateSmelterEndDate">End Date</Label>
												<DatePicker
													id="updateSmelterEndDate"
													value={values.updateSmelterEndDate}
													onBlur={handleBlur}
													onChange={(v, f) => setFieldValue('updateSmelterEndDate', v)}
													invalid={
														errors.updateSmelterEndDate && touched.updateSmelterEndDate
													}
													size="sm"
												/>
												<FormText>Enter an optional end date</FormText>
												<FormFeedback>{errors.updateSmelterEndDate}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" size="sm" type="submit" disabled={isSubmitting}>
										Update smelter
									</Button>{' '}
									<Button color="secondary" size="sm" type="button" onClick={this.toggleUpdate}>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</Modal>

				<Modal isOpen={deleteModal} toggle={this.toggleDelete}>
					<ModalHeader toggle={this.toggleDelete}>
						<i className="fas fa-trash-alt mr-3" />Delete a smelter
					</ModalHeader>
					<ModalBody>
						<p>Please confirm that you really want to delete smelter '{deleteSmelter.name}'?</p>
						<p className="small text-muted">
							Deleting a smelter will remove associated group relationships in the database too, which
							include Captures, PseudoGroup relationships, Updates and UserSmelter sign-ups. A deleted
							smelter nor the data from these relationships can be recovered.
						</p>
						<p className="small text-danger">PROCEED WITH CAUTION!</p>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" size="sm" onClick={this.onDeleteAction}>
							Delete smelter
						</Button>{' '}
						<Button color="secondary" size="sm" onClick={this.toggleDelete}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}

	render() {
		return (
			<DocumentTitle title="Savant Admin | Smelters">
				<Container fluid>
					<Crumbs path={[ { title: 'Smelters' } ]} />
					{this.buildResultsTable()}
					{this.buildModals()}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ regions, smelters }) => {
	return {
		regions, // redux
		smelters // redux
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchRegions: () => dispatch(fetchRegions()),
	fetchSmelters: () => dispatch(fetchSmelters()),
	createSmelter: (data) => dispatch(createSmelter(data)),
	updateSmelter: (data) => dispatch(updateSmelter(data)),
	deleteSmelter: (data) => dispatch(deleteSmelter(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Smelters);
