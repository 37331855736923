import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

import {
	FETCH_SMELTERS,
	FETCH_SMELTERS_ERR,
	CREATE_SMELTER,
	CREATE_SMELTER_ERR,
	UPDATE_SMELTER,
	UPDATE_SMELTER_ERR,
	DELETE_SMELTER,
	DELETE_SMELTER_ERR,
	getAuthHeaders
} from 'actions';
import config from 'config';
import { replacer } from 'utils';

export const fetchSmelters = () => async (dispatch) => {
	const request = API.get('savant-admin-api', config.apiEndpoints.smelters, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_SMELTERS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_SMELTERS_ERR,
				payload: err
			})
	);
};

export const createSmelter = (payload) => async (dispatch) => {
	const request = API.post('savant-admin-api', config.apiEndpoints.createSmelter, {
		headers: await getAuthHeaders(),
		body: payload
	});

	return request.then(
		(response) => {
			dispatch({
				type: CREATE_SMELTER,
				payload: response[0]
			});
			dispatch(fetchSmelters());
			toastr.success('Create Smelter', 'Smelter created successfully');
		},
		(err) => {
			dispatch({
				type: CREATE_SMELTER_ERR,
				payload: err
			});
			toastr.error('Create Smelter', 'There was an error creating the smelter');
		}
	);
};

export const updateSmelter = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.put(
		'savant-admin-api',
		replacer(config.apiEndpoints.updateSmelter, {
			id
		}),
		{
			headers: await getAuthHeaders(),
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: UPDATE_SMELTER,
				payload: response[0]
			});
			dispatch(fetchSmelters());
			toastr.success('Create Smelter', 'Smelter updated successfully');
		},
		(err) => {
			dispatch({
				type: UPDATE_SMELTER_ERR,
				payload: err
			});
			toastr.error('Update Smelter', 'There was an error updating the smelter');
		}
	);
};

export const deleteSmelter = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.del(
		'savant-admin-api',
		replacer(config.apiEndpoints.deleteSmelter, {
			id
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_SMELTER,
				payload: response[0]
			});
			dispatch(fetchSmelters());
			toastr.success('Delete Smelter', 'Smelter deleted successfully');
		},
		(err) => {
			dispatch({
				type: DELETE_SMELTER_ERR,
				payload: err
			});
			toastr.error('Delete Smelter', 'There was an error deleting the smelter');
		}
	);
};
