import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardHeader,
	Container,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	Label
} from 'reactstrap';
import { Auth } from 'aws-amplify';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import { ReactSVG } from 'react-svg';

import { getAuthenticatedUserGroups } from 'actions';
import { LoaderButton } from 'components';

import earthiLogo from 'images/earthi.svg';
import './Login.scss';

export class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			username: '',
			password: '',
			showPassword: false,
			errorMsg: ''
		};
	}

	validateForm() {
		return this.state.username.length > 0 && this.state.password.length > 0;
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
			errorMsg: ''
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.setState({
			isLoading: true
		});

		try {
			await Auth.signIn(this.state.username, this.state.password);
			const { authenticatedUserGroups: authGroups } = await getAuthenticatedUserGroups();
			this.props.userHasAuthenticated(true, authGroups);
			this.props.history.push('/home');
		} catch ({ message: errorMsg }) {
			this.setState({
				isLoading: false,
				errorMsg
			});
		}
	};

	toggleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

	render() {
		return (
			<DocumentTitle title="Savant Admin | Login">
				<BodyClassName className="bg-light">
					<Container fluid>
						<Card className="card-login mx-auto mt-5">
							<CardHeader className="card-logo-header">
								<ReactSVG src={earthiLogo} className="card-logo" />
								<h1 className="sr-only">Savant Admin Client</h1>
							</CardHeader>
							<CardBody>
								<Form onSubmit={this.handleSubmit}>
									<FormGroup>
										<Label for="username">Email</Label>
										<Input
											id="username"
											placeholder="Enter email"
											autoFocus
											value={this.state.username}
											onChange={this.handleChange}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="password">Password</Label>
										<InputGroup>
											<Input
												id="password"
												type={this.state.showPassword ? 'text' : 'password'}
												placeholder="Enter password"
												value={this.state.password}
												onChange={this.handleChange}
											/>
											<InputGroupAddon addonType="append">
												<Button outline color="secondary" onClick={this.toggleShowPassword}>
													<i
														className={
															this.state.showPassword ? (
																'fas fa-eye fa-lg eye-button'
															) : (
																'fas fa-eye-slash fa-lg eye-button'
															)
														}
													/>
													<span className="sr-only">Toggle password display</span>
												</Button>
											</InputGroupAddon>
										</InputGroup>
									</FormGroup>
									{this.state.errorMsg && (
										<Alert color="warning" className="py-2 px-3">
											{this.state.errorMsg}
										</Alert>
									)}
									<LoaderButton
										block
										color="primary"
										disabled={!this.validateForm()}
										type="submit"
										isLoading={this.state.isLoading}
										text="Log in"
										loadingText="Logging in…"
									/>
								</Form>
								<div className="text-center mt-3">
									<NavLink className="small" to="/signUp">
										Sign up
									</NavLink>
									<span className="text-muted"> | </span>

									<NavLink className="small" to="/forgotPassword">
										Forgot password
									</NavLink>
								</div>
							</CardBody>
						</Card>
						<Card className="card-login mx-auto mt-2">
							<CardBody>
								<div className="smaller">
									<strong>System access disclaimer:</strong> This is a restricted computer system
									which may be accessed and used only by authorised personnel. UNAUTHORISED ACCESS OR
									USE OF THIS COMPUTER SYSTEM IS STRICTLY PROHIBITED AND CONSTITUTES AN OFFENCE UNDER
									THE COMPUTER MISUSE ACT 1990 and may subject violators to criminal, civil, and/or
									administrative action. All access and use of this computer system is monitored and
									audited by authorised personnel, therefore there should not be any expectations of
									Right to Privacy on this computer system. All activity is subject to being
									intercepted, recorded, read, copied and disclosed by and to authorised personnel for
									official purposes, including criminal investigations. Access or use of this computer
									system by any person, whether authorised or unauthorised, constitutes consent to
									these terms.
								</div>
							</CardBody>
						</Card>
					</Container>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(Login);
