import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import DocumentTitle from 'react-document-title';
import {
	Button,
	Col,
	Container,
	CustomInput,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormFeedback,
	Form,
	FormGroup,
	FormText,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
	UncontrolledDropdown
} from 'reactstrap';
import Select from 'react-select';

import {
	fetchSmelters,
	fetchPseudoGroups,
	createPseudoGroup,
	updatePseudoGroup,
	deletePseudoGroup,
	fetchPseudoGroupSecurityGroups,
	createPseudoGroupSecurityGroup,
	updatePseudoGroupSecurityGroup,
	deletePseudoGroupSecurityGroup
} from 'actions';
import config from 'config';

import { Crumbs } from 'containers';

import './PseudoGroups.scss';
import { multiSelectStyle } from './multiSelectStyle';

class PseudoGroups extends Component {
	state = {
		createModal: false,
		updateModal: false,
		updatePseudoGroup: {},
		deleteModal: false,
		deletePseudoGroup: {},
		securityGroupModal: false,
		securityGroupPseudoGroup: {},
		createSecurityGroupLine: false,
		updateSecurityGroup: null,
		deleteSecurityGroupModal: false,
		deleteSecurityGroup: {},
		typeFilter: '',
		nameFilter: ''
	};

	componentDidMount() {
		this.props.fetchSmelters();
		this.props.fetchPseudoGroups();
	}

	onReloadList = () => {
		this.props.fetchPseudoGroups();
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	toggleCreate = () => {
		this.setState((prevState) => ({
			createModal: !prevState.createModal
		}));
	};

	onCreateAction = async ({
		values: {
			createPseudoGroupKey,
			createPseudoGroupName,
			createPseudoGroupColour,
			createPseudoGroupTooltip,
			createPseudoGroupType,
			createPseudoGroupSmelters
		},
		setSubmitting
	}) => {
		// Insert the new smelter into the db
		await this.props.createPseudoGroup({
			key: createPseudoGroupKey.trim(),
			name: createPseudoGroupName.trim(),
			colour: createPseudoGroupColour.trim(),
			tooltip: createPseudoGroupTooltip.trim(),
			type: createPseudoGroupType.trim(),
			smelters: createPseudoGroupSmelters.map(({ value }) => value).join(',')
		});

		setSubmitting(false);

		this.setState({
			createModal: false
		});
	};

	toggleUpdate = () => {
		this.setState((prevState) => ({
			updateModal: !prevState.updateModal
		}));
	};

	onUpdate = ({ pseudogroup }) => {
		this.setState({
			updatePseudoGroup: pseudogroup,
			updateModal: true
		});
	};

	onUpdateAction = async ({
		values: {
			updatePseudoGroupId,
			updatePseudoGroupKey,
			updatePseudoGroupName,
			updatePseudoGroupColour,
			updatePseudoGroupTooltip,
			updatePseudoGroupType,
			updatePseudoGroupSmelters
		},
		setSubmitting
	}) => {
		// Update the pseudogroup in the db
		await this.props.updatePseudoGroup({
			id: updatePseudoGroupId,
			key: updatePseudoGroupKey.trim(),
			name: updatePseudoGroupName.trim(),
			colour: updatePseudoGroupColour.trim(),
			tooltip: updatePseudoGroupTooltip.trim(),
			type: updatePseudoGroupType.trim(),
			smelters: updatePseudoGroupSmelters.map(({ value }) => value).join(',')
		});

		setSubmitting(false);

		this.setState({
			updateModal: false,
			updatePseudoGroup: {}
		});
	};

	toggleDelete = () => {
		this.setState((prevState) => ({
			deleteModal: !prevState.deleteModal
		}));
	};

	onDelete = ({ pseudogroup }) => {
		this.setState({
			deletePseudoGroup: pseudogroup,
			deleteModal: true
		});
	};

	onDeleteAction = async () => {
		const { deletePseudoGroup: { id } } = this.state;

		// Delete the pseudogroup in the db
		await this.props.deletePseudoGroup({
			id
		});

		this.setState({
			deleteModal: false,
			deletePseudoGroup: {}
		});
	};

	togglePseudoGroupSecurityGroups = () => {
		this.setState((prevState) => ({
			securityGroupModal: !prevState.securityGroupModal,
			createSecurityGroupLine: false,
			updateSecurityGroup: null
		}));
	};

	onDisplaySecurityGroups = ({ pseudogroup }) => {
		this.setState({
			securityGroupPseudoGroup: pseudogroup,
			securityGroupModal: true
		});

		this.props.fetchPseudoGroupSecurityGroups(pseudogroup);
	};

	onCreateSecurityGroup = () => {
		this.setState({
			createSecurityGroupLine: true,
			updateSecurityGroup: null
		});
	};

	toggleCreateSecurityGroup = () => {
		this.setState((prevState) => ({
			createSecurityGroupLine: !prevState.createSecurityGroupLine
		}));
	};

	onCreateSecurityGroupAction = async ({
		values: { createSecurityGroupCognitoGroup, createSecurityGroupType, createSecurityGroupSort },
		setSubmitting
	}) => {
		const { securityGroupPseudoGroup } = this.state;

		if (securityGroupPseudoGroup) {
			// Insert the new security group into the db
			await this.props.createPseudoGroupSecurityGroup({
				id: securityGroupPseudoGroup.id,
				cognito_group: createSecurityGroupCognitoGroup.trim(),
				pseudogroup_id: securityGroupPseudoGroup.id,
				sort_order: createSecurityGroupSort.trim(),
				type: createSecurityGroupType.trim()
			});
		}

		setSubmitting(false);

		this.setState({
			createSecurityGroupLine: false
		});

		this.props.fetchPseudoGroupSecurityGroups(securityGroupPseudoGroup);
	};

	onUpdateSecurityGroup = ({ securitygroup }) => {
		this.setState({
			createSecurityGroupLine: false,
			updateSecurityGroup: securitygroup
		});
	};

	onCancelUpdateSecurityGroup = () => {
		this.setState({
			updateSecurityGroup: null
		});
	};

	onUpdateSecurityGroupAction = async ({
		values: { updateSecurityGroupCognitoGroup, updateSecurityGroupType, updateSecurityGroupSort },
		setSubmitting
	}) => {
		const { securityGroupPseudoGroup, updateSecurityGroup } = this.state;

		if (securityGroupPseudoGroup && updateSecurityGroup) {
			// Insert the new security group into the db
			await this.props.updatePseudoGroupSecurityGroup({
				id: securityGroupPseudoGroup.id,
				securityGroupId: updateSecurityGroup.id,
				cognito_group: updateSecurityGroupCognitoGroup.trim(),
				sort_order: updateSecurityGroupSort.toString().trim(),
				type: updateSecurityGroupType.trim()
			});
		}

		setSubmitting(false);

		this.setState({
			updateSecurityGroup: null
		});

		this.props.fetchPseudoGroupSecurityGroups(securityGroupPseudoGroup);
	};

	toggleSecurityGroupDelete = () => {
		this.setState((prevState) => ({
			deleteSecurityGroupModal: !prevState.deleteSecurityGroupModal
		}));
	};

	onDeleteSecurityGroup = ({ securitygroup }) => {
		this.setState({
			deleteSecurityGroup: securitygroup,
			deleteSecurityGroupModal: true
		});
	};

	onDeleteSecurityGroupAction = async () => {
		const { securityGroupPseudoGroup, deleteSecurityGroup: { id } } = this.state;

		// Delete the security group in the db
		await this.props.deletePseudoGroupSecurityGroup({
			id: securityGroupPseudoGroup.id,
			securityGroupId: id
		});

		this.setState({
			deleteSecurityGroupModal: false,
			deleteSecurityGroup: {}
		});

		this.props.fetchPseudoGroupSecurityGroups(securityGroupPseudoGroup);
	};

	buildResultsTable() {
		let { pseudoGroups = [] } = this.props;
		const { typeFilter, nameFilter } = this.state;

		if (nameFilter !== '') {
			pseudoGroups = pseudoGroups.filter(({ name }) => {
				return name.toLowerCase().includes(nameFilter.toLowerCase());
			});
		}
		if (typeFilter !== '') {
			pseudoGroups = pseudoGroups.filter(({ type }) => {
				return type === typeFilter;
			});
		}

		return (
			<Row noGutters>
				<Col xs="12">
					<Table>
						<thead>
							<tr>
								<th style={{ width: '60px' }}>#</th>
								<th>Key</th>
								<th>Type</th>
								<th colSpan="2">
									PseudoGroup name
									<div
										className="position-absolute align-bottom"
										style={{ right: '12px', top: '10px' }}
									>
										<CustomInput
											id="nameFilter"
											name="nameFilter"
											type="text"
											className="mr-2 name-filter"
											onChange={(event) => this.handleChange(event)}
											defaultValue={nameFilter}
											placeholder="Name search"
											bsSize="sm"
											inline
										/>
										<CustomInput
											id="typeFilter"
											name="typeFilter"
											type="select"
											className="mr-2 facet-select"
											onChange={(event) => this.handleChange(event)}
											defaultValue={typeFilter}
											bsSize="sm"
											inline
										>
											<option value="">Any type</option>
											{Object.entries(config.types).map(([ key, value ], idx) => (
												<option value={key} key={`type${idx}`}>
													{value}
												</option>
											))}
										</CustomInput>
										<Button size="sm" color="success" className="mr-2" onClick={this.toggleCreate}>
											<i className="fas fa-plus mr-2" />Add pseudo group
										</Button>
										<Button size="sm" outline color="secondary" onClick={this.onReloadList}>
											<i className="fas fa-sync-alt" />
											<span className="sr-only">Refresh</span>
										</Button>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{pseudoGroups.map((pseudogroup, ind) => {
								const { key, type, name } = pseudogroup;
								const dspInd = ind + 1;
								return (
									<tr key={`region${dspInd}`}>
										<th scope="row" style={{ width: '60px' }}>
											{dspInd}
										</th>
										<td style={{ width: '200px' }}>{key}</td>
										<td style={{ width: '90px' }}>{config.types[type]}</td>
										<td>{name}</td>
										<td style={{ width: '45px' }}>
											<UncontrolledDropdown size="sm">
												<DropdownToggle className="pointered btn-light">
													<i className="fas fa-bars" />
													<span className="sr-only">Actions</span>
												</DropdownToggle>
												<DropdownMenu>
													<DropdownItem
														className="pointered"
														onClick={(e) => this.onUpdate({ pseudogroup }, e)}
													>
														<i className="fas fa-edit fa-fw mr-2" />Update
													</DropdownItem>
													<DropdownItem
														className="pointered"
														onClick={(e) =>
															this.onDisplaySecurityGroups({ pseudogroup }, e)}
													>
														<i className="fas fa-list fa-fw mr-2" />Security groups
													</DropdownItem>
													<DropdownItem divider />
													<DropdownItem
														className="pointered"
														onClick={(e) => this.onDelete({ pseudogroup }, e)}
													>
														<i className="fas fa-trash-alt fa-fw mr-2" />Delete
													</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		);
	}

	buildModals() {
		const {
			createModal,
			updateModal,
			updatePseudoGroup,
			deleteModal,
			deletePseudoGroup,
			createSecurityGroupLine,
			securityGroupModal,
			securityGroupPseudoGroup: { name: securityGroupPseudoGroupName = '' },
			updateSecurityGroup,
			deleteSecurityGroupModal,
			deleteSecurityGroup
		} = this.state;
		const { smelters, pseudoGroupSecurityGroups } = this.props;

		const multiSelectSmelters = smelters.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => {
			return {
				value: id,
				label: name
			};
		});

		let updatePseudoGroupInitialSmelters = [];
		if (updatePseudoGroup && updatePseudoGroup.smelter_ids) {
			const updatePseudoGroupSmelters = updatePseudoGroup.smelter_ids.split(',');
			updatePseudoGroupInitialSmelters = multiSelectSmelters.filter(({ value }) =>
				updatePseudoGroupSmelters.includes(value.toString())
			);
		}

		return (
			<Fragment>
				<Modal isOpen={createModal} toggle={this.toggleCreate}>
					<Formik
						initialValues={{
							createPseudoGroupKey: '',
							createPseudoGroupName: '',
							createPseudoGroupColour: '',
							createPseudoGroupTooltip: '',
							createPseudoGroupType: '',
							createPseudoGroupSmelters: []
						}}
						validate={(values) => {
							const errors = {};
							if (values.createPseudoGroupKey.trim().length === 0) {
								errors.createPseudoGroupKey = 'A key is required';
							} else if (values.createPseudoGroupKey.trim().length > 20) {
								errors.createPseudoGroupKey = 'Key should be no more than 20 characters';
							}
							if (values.createPseudoGroupName.trim().length === 0) {
								errors.createPseudoGroupName = 'A name is required';
							} else if (values.createPseudoGroupName.trim().length > 256) {
								errors.createPseudoGroupName = 'Name should be no more than 256 characters';
							}
							if (values.createPseudoGroupColour.trim().length === 0) {
								errors.createPseudoGroupColour = 'A colour is required';
							} else if (values.createPseudoGroupColour.trim().length > 10) {
								errors.createPseudoGroupColour = 'Colour should be no more than 10 characters';
							}
							if (values.createPseudoGroupTooltip.trim().length > 256) {
								errors.createPseudoGroupTooltip = 'Tooltip should be no more than 256 characters';
							}
							if (values.createPseudoGroupType.trim().length === 0) {
								errors.createPseudoGroupType = 'A type is required';
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onCreateAction({ values, setSubmitting });
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setFieldValue
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalHeader toggle={this.toggleCreate}>
									<i className="fas fa-plus mr-3" />Create a new pseudo group
								</ModalHeader>
								<ModalBody>
									<FormGroup>
										<Label for="createPseudoGroupName">Pseudo group name</Label>
										<Input
											tag={Field}
											id="createPseudoGroupName"
											placeholder="China NPI"
											value={values.createPseudoGroupName}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={errors.createPseudoGroupName && touched.createPseudoGroupName}
											bsSize="sm"
										/>
										<FormText>Pseudo group name should be no more than 256 characters</FormText>
										<FormFeedback>{errors.createPseudoGroupName}</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="createPseudoGroupTooltip">Tooltip (optional)</Label>
										<Input
											tag={Field}
											id="createPseudoGroupTooltip"
											placeholder="China<br />(incl. NPI)"
											value={values.createPseudoGroupTooltip}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={
												errors.createPseudoGroupTooltip && touched.createPseudoGroupTooltip
											}
											bsSize="sm"
										/>
										<FormText>Optional tooltip should be no more than 256 characters</FormText>
										<FormFeedback>{errors.createPseudoGroupTooltip}</FormFeedback>
									</FormGroup>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="createPseudoGroupKey">Key</Label>
												<Input
													tag={Field}
													id="createPseudoGroupKey"
													placeholder="chinanpi"
													value={values.createPseudoGroupKey}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.createPseudoGroupKey && touched.createPseudoGroupKey
													}
													bsSize="sm"
												/>
												<FormText>Key should be no more than 20 characters</FormText>
												<FormFeedback>{errors.createPseudoGroupKey}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="createPseudoGroupColour">Colour</Label>
												<Input
													tag={Field}
													id="createPseudoGroupColour"
													placeholder="#9c9c9c"
													value={values.createPseudoGroupColour}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.createPseudoGroupColour &&
														touched.createPseudoGroupColour
													}
													bsSize="sm"
												/>
												<FormText>Colour should be a hexadecimal value</FormText>
												<FormFeedback>{errors.createPseudoGroupColour}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="createPseudoGroupType">Type</Label>
												<CustomInput
													id="createPseudoGroupType"
													type="select"
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.createPseudoGroupType}
													invalid={
														errors.createPseudoGroupType && touched.createPseudoGroupType
													}
													bsSize="sm"
												>
													<option value="">Please select a type</option>
													{Object.entries(config.types).map(([ key, value ], idx) => (
														<option value={key} key={`type${idx}`}>
															{value}
														</option>
													))}
												</CustomInput>
												<FormText>A type is required</FormText>
												<FormFeedback>{errors.createPseudoGroupType}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<FormGroup>
										<Label for="createPseudoGroupSmelters">Smelters</Label>
										<Select
											id="createPseudoGroupSmelters"
											isMulti
											options={multiSelectSmelters}
											value={values.createPseudoGroupSmelters}
											onChange={(option) => {
												setFieldValue(
													'createPseudoGroupSmelters',
													option ? option.map((item) => item) : []
												);
											}}
											classNamePrefix="react-select"
											placeholder="Select Smelters..."
											styles={multiSelectStyle}
										/>
										<FormText>
											Please select Smelters that should belong to this pseudo group
										</FormText>
									</FormGroup>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" size="sm" type="submit" disabled={isSubmitting}>
										Create pseudo group
									</Button>{' '}
									<Button color="secondary" size="sm" type="button" onClick={this.toggleCreate}>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</Modal>

				<Modal isOpen={updateModal} toggle={this.toggleUpdate}>
					<Formik
						initialValues={{
							updatePseudoGroupId: updatePseudoGroup.id,
							updatePseudoGroupKey: updatePseudoGroup.key,
							updatePseudoGroupName: updatePseudoGroup.name,
							updatePseudoGroupColour: updatePseudoGroup.colour,
							updatePseudoGroupTooltip: updatePseudoGroup.tooltip,
							updatePseudoGroupType: updatePseudoGroup.type,
							updatePseudoGroupSmelters: updatePseudoGroupInitialSmelters
						}}
						validate={(values) => {
							const errors = {};
							if (values.updatePseudoGroupKey.trim().length === 0) {
								errors.updatePseudoGroupKey = 'A key is required';
							} else if (values.updatePseudoGroupKey.trim().length > 20) {
								errors.updatePseudoGroupKey = 'Key should be no more than 20 characters';
							}
							if (values.updatePseudoGroupName.trim().length === 0) {
								errors.updatePseudoGroupName = 'A name is required';
							} else if (values.updatePseudoGroupName.trim().length > 256) {
								errors.updatePseudoGroupName = 'Name should be no more than 256 characters';
							}
							if (values.updatePseudoGroupColour.trim().length === 0) {
								errors.updatePseudoGroupColour = 'A colour is required';
							} else if (values.updatePseudoGroupColour.trim().length > 10) {
								errors.updatePseudoGroupColour = 'Colour should be no more than 10 characters';
							}
							if (values.updatePseudoGroupTooltip.trim().length > 256) {
								errors.updatePseudoGroupTooltip = 'Tooltip should be no more than 256 characters';
							}
							if (values.updatePseudoGroupType.trim().length === 0) {
								errors.updatePseudoGroupType = 'A type is required';
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onUpdateAction({ values, setSubmitting });
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setFieldValue
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalHeader toggle={this.toggleUpdate}>
									<i className="fas fa-plus mr-3" />Update pseudo group
								</ModalHeader>
								<ModalBody>
									<FormGroup>
										<Label for="updatePseudoGroupName">Pseudo group name</Label>
										<Input
											tag={Field}
											id="updatePseudoGroupName"
											placeholder="China NPI"
											value={values.updatePseudoGroupName}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={errors.updatePseudoGroupName && touched.updatePseudoGroupName}
											bsSize="sm"
										/>
										<FormText>Pseudo group name should be no more than 256 characters</FormText>
										<FormFeedback>{errors.updatePseudoGroupName}</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="updatePseudoGroupTooltip">Tooltip (optional)</Label>
										<Input
											tag={Field}
											id="updatePseudoGroupTooltip"
											placeholder="China<br />(incl. NPI)"
											value={values.updatePseudoGroupTooltip}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={
												errors.updatePseudoGroupTooltip && touched.updatePseudoGroupTooltip
											}
											bsSize="sm"
										/>
										<FormText>Optional tooltip should be no more than 256 characters</FormText>
										<FormFeedback>{errors.updatePseudoGroupTooltip}</FormFeedback>
									</FormGroup>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="updatePseudoGroupKey">Key</Label>
												<Input
													tag={Field}
													id="updatePseudoGroupKey"
													placeholder="chinanpi"
													value={values.updatePseudoGroupKey}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.updatePseudoGroupKey && touched.updatePseudoGroupKey
													}
													bsSize="sm"
												/>
												<FormText>Key should be no more than 20 characters</FormText>
												<FormFeedback>{errors.updatePseudoGroupKey}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="updatePseudoGroupColour">Colour</Label>
												<Input
													tag={Field}
													id="updatePseudoGroupColour"
													placeholder="#9c9c9c"
													value={values.updatePseudoGroupColour}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.updatePseudoGroupColour &&
														touched.updatePseudoGroupColour
													}
													bsSize="sm"
												/>
												<FormText>Colour should be a hexadecimal value</FormText>
												<FormFeedback>{errors.updatePseudoGroupColour}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="updatePseudoGroupType">Type</Label>
												<CustomInput
													id="updatePseudoGroupType"
													type="select"
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.updatePseudoGroupType}
													invalid={
														errors.updatePseudoGroupType && touched.updatePseudoGroupType
													}
													bsSize="sm"
												>
													<option value="">Please select a type</option>
													{Object.entries(config.types).map(([ key, value ], idx) => (
														<option value={key} key={`type${idx}`}>
															{value}
														</option>
													))}
												</CustomInput>
												<FormText>A type is required</FormText>
												<FormFeedback>{errors.updatePseudoGroupType}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<FormGroup>
										<Label for="updatePseudoGroupSmelters">Smelters</Label>
										<Select
											id="updatePseudoGroupSmelters"
											isMulti
											options={multiSelectSmelters}
											value={values.updatePseudoGroupSmelters}
											onChange={(option) => {
												setFieldValue(
													'updatePseudoGroupSmelters',
													option ? option.map((item) => item) : []
												);
											}}
											classNamePrefix="react-select"
											placeholder="Select Smelters..."
											styles={multiSelectStyle}
										/>
										<FormText>
											Please select Smelters that should belong to this pseudo group
										</FormText>
									</FormGroup>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" size="sm" type="submit" disabled={isSubmitting}>
										Update pseudo group
									</Button>{' '}
									<Button color="secondary" size="sm" type="button" onClick={this.toggleUpdate}>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</Modal>

				<Modal isOpen={deleteModal} toggle={this.toggleDelete}>
					<ModalHeader toggle={this.toggleDelete}>
						<i className="fas fa-trash-alt mr-3" />Delete a pseudo group
					</ModalHeader>
					<ModalBody>
						<p>Please confirm that you really want to delete pseudo group '{deletePseudoGroup.name}'?</p>
						<p className="small text-muted">
							Deleting a pseudo group will remove associated group relationships in the database too,
							which include Smelter relationships and Security Group relationships. A deleted pseudo group
							nor the data from these relationships can be recovered.
						</p>
						<p className="small text-danger">PROCEED WITH CAUTION!</p>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" size="sm" onClick={this.onDeleteAction}>
							Delete pseudo group
						</Button>{' '}
						<Button color="secondary" size="sm" onClick={this.toggleDelete}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>

				<Modal size="lg" isOpen={securityGroupModal} toggle={this.togglePseudoGroupSecurityGroups}>
					<ModalHeader toggle={this.togglePseudoGroupSecurityGroups}>
						<i className="fas fa-list mr-3" />Manage security groups for pseudo group '{securityGroupPseudoGroupName}'
					</ModalHeader>
					<ModalBody>
						<Table striped size="sm" className="reducedBorders">
							<thead>
								<tr>
									<th style={{ width: '5%' }}>#</th>
									<th style={{ width: '55%' }}>Group</th>
									<th style={{ width: '25%' }}>Type</th>
									<th style={{ width: '7%', textAlign: 'center' }}>Sort</th>
									<th style={{ width: '8%', textAlign: 'center' }} />
								</tr>
							</thead>
							<tbody>
								{pseudoGroupSecurityGroups.map((securitygroup, ind) => {
									const { id, cognito_group, sort_order, type } = securitygroup;
									const dspInd = ind + 1;

									if (updateSecurityGroup && updateSecurityGroup.id === id) {
										return (
											<Formik
												initialValues={{
													updateSecurityGroupCognitoGroup: updateSecurityGroup.cognito_group,
													updateSecurityGroupType: updateSecurityGroup.type,
													updateSecurityGroupSort: updateSecurityGroup.sort_order
												}}
												validate={(values) => {
													const errors = {};
													if (values.updateSecurityGroupCognitoGroup.trim().length === 0) {
														errors.updateSecurityGroupCognitoGroup = 'A group is required';
													}
													if (values.updateSecurityGroupType.trim().length === 0) {
														errors.updateSecurityGroupType = 'A type is required';
													}
													if (values.updateSecurityGroupSort.toString().trim().length === 0) {
														errors.updateSecurityGroupSort = 'A sort is required';
													}
													return errors;
												}}
												onSubmit={(values, { setSubmitting }) => {
													this.onUpdateSecurityGroupAction({ values, setSubmitting });
												}}
											>
												{({
													values,
													errors,
													touched,
													handleChange,
													handleBlur,
													handleSubmit
												}) => (
													<tr key={`securitygroup${dspInd}`}>
														<th scope="row" style={{ width: '5%' }}>
															{dspInd}
														</th>
														<td style={{ width: '55%' }}>
															<CustomInput
																id="updateSecurityGroupCognitoGroup"
																type="select"
																onChange={handleChange}
																onBlur={handleBlur}
																defaultValue={values.updateSecurityGroupCognitoGroup}
																invalid={
																	errors.updateSecurityGroupCognitoGroup &&
																	touched.updateSecurityGroupCognitoGroup
																}
																bsSize="sm"
															>
																<option value="">Please select a group</option>
																{config.cognitoSecurityGroups.map((grp, idx) => (
																	<option value={grp} key={`cogGrg${idx}`}>
																		{grp}
																	</option>
																))}
															</CustomInput>
														</td>
														<td style={{ width: '25%' }}>
															<Input
																tag={Field}
																id="updateSecurityGroupType"
																placeholder="default"
																value={values.updateSecurityGroupType}
																onChange={handleChange}
																onBlur={handleBlur}
																invalid={
																	errors.updateSecurityGroupType &&
																	touched.updateSecurityGroupType
																}
																bsSize="sm"
															/>
														</td>
														<td style={{ width: '7%', textAlign: 'center' }}>
															<Input
																tag={Field}
																id="updateSecurityGroupSort"
																placeholder="0"
																value={values.updateSecurityGroupSort}
																onChange={handleChange}
																onBlur={handleBlur}
																invalid={
																	errors.updateSecurityGroupSort &&
																	touched.updateSecurityGroupSort
																}
																bsSize="sm"
															/>
														</td>
														<td style={{ width: '8%', textAlign: 'right' }}>
															<i
																className="fas fa-check fa-fw pointered text-success mr-1 align-middle"
																onClick={handleSubmit}
															>
																<span className="sr-only">
																	Save security group update
																</span>
															</i>
															<i
																className="fas fa-ban fa-fw pointered text-danger align-middle"
																onClick={(e) => this.onCancelUpdateSecurityGroup()}
															>
																<span className="sr-only">
																	Cancel security group update
																</span>
															</i>
														</td>
													</tr>
												)}
											</Formik>
										);
									}

									return (
										<tr key={`securitygroup${dspInd}`}>
											<th scope="row" style={{ width: '5%' }}>
												{dspInd}
											</th>
											<td style={{ width: '55%' }}>{cognito_group}</td>
											<td style={{ width: '25%' }}>{type}</td>
											<td style={{ width: '7%', textAlign: 'center' }}>{sort_order}</td>
											<td style={{ width: '8%', textAlign: 'right' }}>
												<i
													className="far fa-edit fa-fw pointered text-muted mr-1"
													onClick={(e) => this.onUpdateSecurityGroup({ securitygroup }, e)}
												>
													<span className="sr-only">Edit security group</span>
												</i>
												<i
													className="far fa-trash-alt fa-fw pointered text-danger"
													onClick={(e) => this.onDeleteSecurityGroup({ securitygroup }, e)}
												>
													<span className="sr-only">Delete security group</span>
												</i>
											</td>
										</tr>
									);
								})}
								{!createSecurityGroupLine && (
									<tr>
										<th />
										<td style={{ width: '55%' }} />
										<td style={{ width: '25%' }} />
										<td style={{ width: '7%' }} />
										<td style={{ width: '8%', textAlign: 'right' }}>
											<i
												className="fas fa-plus fa-fw pointered text-success align-middle"
												onClick={(e) => this.onCreateSecurityGroup()}
											>
												<span className="sr-only">Add security group create</span>
											</i>
										</td>
									</tr>
								)}
								{createSecurityGroupLine && (
									<Formik
										initialValues={{
											createSecurityGroupCognitoGroup: '',
											createSecurityGroupType: '',
											createSecurityGroupSort: '0'
										}}
										validate={(values) => {
											const errors = {};
											if (values.createSecurityGroupCognitoGroup.trim().length === 0) {
												errors.createSecurityGroupCognitoGroup = 'A group is required';
											}
											if (values.createSecurityGroupType.trim().length === 0) {
												errors.createSecurityGroupType = 'A type is required';
											}
											if (values.createSecurityGroupSort.trim().length === 0) {
												errors.createSecurityGroupSort = 'A sort is required';
											}
											return errors;
										}}
										onSubmit={(values, { setSubmitting }) => {
											this.onCreateSecurityGroupAction({ values, setSubmitting });
										}}
									>
										{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
											<tr>
												<th scope="row" style={{ width: '5%' }}>
													{pseudoGroupSecurityGroups.length + 1}
												</th>
												<td style={{ width: '55%' }}>
													<CustomInput
														id="createSecurityGroupCognitoGroup"
														type="select"
														onChange={handleChange}
														onBlur={handleBlur}
														defaultValue={values.createSecurityGroupCognitoGroup}
														invalid={
															errors.createSecurityGroupCognitoGroup &&
															touched.createSecurityGroupCognitoGroup
														}
														bsSize="sm"
													>
														<option value="">Please select a group</option>
														{config.cognitoSecurityGroups.map((grp, idx) => (
															<option value={grp} key={`cogGrg${idx}`}>
																{grp}
															</option>
														))}
													</CustomInput>
												</td>
												<td style={{ width: '25%' }}>
													<Input
														tag={Field}
														id="createSecurityGroupType"
														placeholder="default"
														value={values.createSecurityGroupType}
														onChange={handleChange}
														onBlur={handleBlur}
														invalid={
															errors.createSecurityGroupType &&
															touched.createSecurityGroupType
														}
														bsSize="sm"
													/>
												</td>
												<td style={{ width: '7%', textAlign: 'center' }}>
													<Input
														tag={Field}
														id="createSecurityGroupSort"
														placeholder="0"
														value={values.createSecurityGroupSort}
														onChange={handleChange}
														onBlur={handleBlur}
														invalid={
															errors.createSecurityGroupSort &&
															touched.createSecurityGroupSort
														}
														bsSize="sm"
													/>
												</td>
												<td style={{ width: '8%', textAlign: 'right' }}>
													<i
														className="fas fa-check fa-fw pointered text-success mr-1 align-middle"
														onClick={handleSubmit}
													>
														<span className="sr-only">Save security group create</span>
													</i>
													<i
														className="fas fa-ban fa-fw pointered text-danger align-middle"
														onClick={(e) => this.toggleCreateSecurityGroup()}
													>
														<span className="sr-only">Cancel security group create</span>
													</i>
												</td>
											</tr>
										)}
									</Formik>
								)}
							</tbody>
						</Table>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" size="sm" onClick={this.togglePseudoGroupSecurityGroups}>
							Close
						</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={deleteSecurityGroupModal} toggle={this.toggleSecurityGroupDelete}>
					<ModalHeader toggle={this.toggleSecurityGroupDelete}>
						<i className="fas fa-trash-alt mr-3" />Delete a security group
					</ModalHeader>
					<ModalBody>
						<p>
							Please confirm that you really want to delete the '{deleteSecurityGroup.cognito_group}'
							security group?
						</p>
						<p className="small text-muted">A deleted security group cannot be recovered.</p>
						<p className="small text-danger">PROCEED WITH CAUTION!</p>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" size="sm" onClick={this.onDeleteSecurityGroupAction}>
							Delete security group
						</Button>{' '}
						<Button color="secondary" size="sm" onClick={this.toggleSecurityGroupDelete}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}

	render() {
		return (
			<DocumentTitle title="Savant Admin | PseudoGroups">
				<Container fluid>
					<Crumbs path={[ { title: 'PseudoGroups' } ]} />
					{this.buildResultsTable()}
					{this.buildModals()}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ smelters, pseudoGroups, pseudoGroupSecurityGroups }) => {
	return {
		smelters, // redux
		pseudoGroups, // redux
		pseudoGroupSecurityGroups //redux
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchSmelters: () => dispatch(fetchSmelters()),
	fetchPseudoGroups: () => dispatch(fetchPseudoGroups()),
	createPseudoGroup: (data) => dispatch(createPseudoGroup(data)),
	updatePseudoGroup: (data) => dispatch(updatePseudoGroup(data)),
	deletePseudoGroup: (data) => dispatch(deletePseudoGroup(data)),
	fetchPseudoGroupSecurityGroups: (data) => dispatch(fetchPseudoGroupSecurityGroups(data)),
	createPseudoGroupSecurityGroup: (data) => dispatch(createPseudoGroupSecurityGroup(data)),
	updatePseudoGroupSecurityGroup: (data) => dispatch(updatePseudoGroupSecurityGroup(data)),
	deletePseudoGroupSecurityGroup: (data) => dispatch(deletePseudoGroupSecurityGroup(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PseudoGroups);
