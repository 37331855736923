import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import {
	Button,
	Col,
	Container,
	CustomInput,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
	UncontrolledDropdown,
	ButtonGroup
} from 'reactstrap';

import {
	fetchUsersStaging,
	fetchUsersProduction,
	enableUserStaging,
	enableUserProduction,
	disableUserStaging,
	disableUserProduction,
	deleteUserStaging,
	deleteUserProduction,
	removeUserSecurityGroupStaging,
	removeUserSecurityGroupProduction,
	addUserSecurityGroupStaging,
	addUserSecurityGroupProduction
} from 'actions';
import config from 'config';

import { Crumbs } from 'containers';

import './Users.scss';

class Users extends Component {
	state = {
		typeFilter: 'staging',
		nameFilter: '',
		deleteModal: false,
		deleteUser: {},
		groupsModal: false,
		groupsUser: {},
		addGroup: '',
		preparedState: null
	};

	componentDidMount() {
		this.props.fetchUsersStaging();
		this.props.fetchUsersProduction();
	}

	componentDidUpdate({ userProcess: pUserProcess }) {
		const { userProcess } = this.props;
		const { preparedState } = this.state;

		if (pUserProcess === null && userProcess === true && preparedState != null) {
			this.setState({
				...preparedState,
				preparedState: null
			});
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	onReloadList = () => {
		this.props.fetchUsersStaging();
		this.props.fetchUsersProduction();
	};

	onDisableUserAction = async ({ user: { username } }) => {
		const { typeFilter } = this.state;

		// Disable the user in cognito
		if (typeFilter === 'production') {
			await this.props.disableUserProduction({
				username
			});
		} else if (typeFilter === 'staging') {
			await this.props.disableUserStaging({
				username
			});
		}
	};

	onEnableUserAction = async ({ user: { username } }) => {
		const { typeFilter } = this.state;

		// Enable the user in cognito
		if (typeFilter === 'production') {
			await this.props.enableUserProduction({
				username
			});
		} else if (typeFilter === 'staging') {
			await this.props.enableUserStaging({
				username
			});
		}
	};

	toggleDelete = () => {
		this.setState((prevState) => ({
			deleteModal: !prevState.deleteModal
		}));
	};

	onDeleteUser = ({ user }) => {
		this.setState({
			deleteUser: user,
			deleteModal: true
		});
	};

	onDeleteUserAction = async () => {
		const { typeFilter, deleteUser: { username } } = this.state;

		// Delete the user in cognito
		if (typeFilter === 'production') {
			await this.props.deleteUserProduction({
				username
			});
		} else if (typeFilter === 'staging') {
			await this.props.deleteUserStaging({
				username
			});
		}

		this.setState({
			deleteModal: false,
			deleteUser: {}
		});
	};

	toggleGroups = () => {
		this.setState((prevState) => ({
			groupsModal: !prevState.groupsModal
		}));
	};

	onUserGroups = ({ user }) => {
		this.setState({
			groupsUser: user,
			groupsModal: true
		});
	};

	onRemoveUserSecurityGroupAction = async ({ groupsUser, group }) => {
		const { typeFilter } = this.state;

		this.setState({
			preparedState: {
				groupsUser: {
					...groupsUser,
					groups: groupsUser.groups.filter((grp) => grp !== group)
				}
			}
		});

		// Delete the user group in cognito
		if (typeFilter === 'production') {
			await this.props.removeUserSecurityGroupProduction({
				username: groupsUser.username,
				usergroup: group
			});
		} else if (typeFilter === 'staging') {
			await this.props.removeUserSecurityGroupStaging({
				username: groupsUser.username,
				usergroup: group
			});
		}
	};

	onAddUserSecurityGroupAction = async ({ groupsUser, group }) => {
		const { typeFilter } = this.state;
		const currentGroups = groupsUser.groups || [];

		this.setState({
			preparedState: {
				groupsUser: {
					...groupsUser,
					groups: [ ...currentGroups, group ]
				}
			},
			addGroup: ''
		});

		// Add the user group in cognito
		if (typeFilter === 'production') {
			await this.props.addUserSecurityGroupProduction({
				username: groupsUser.username,
				usergroup: group
			});
		} else if (typeFilter === 'staging') {
			await this.props.addUserSecurityGroupStaging({
				username: groupsUser.username,
				usergroup: group
			});
		}
	};

	buildResultsTable() {
		const { usersStaging = [], usersProduction = [] } = this.props;
		const { typeFilter, nameFilter } = this.state;

		const dspUsers = (typeFilter === 'production' ? usersProduction : usersStaging)
			.map(
				({
					Username: username,
					Enabled: enabled,
					UserStatus: status,
					UserCreatedDate: created,
					groups,
					Attributes: attributes
				}) => {
					let attrs = {};
					attributes.forEach(({ Name, Value }) => {
						if (Name === 'sub') attrs['sub'] = Value;
						if (Name === 'given_name') attrs['givenName'] = Value;
						if (Name === 'family_name') attrs['familyName'] = Value;
						if (Name === 'email') attrs['email'] = Value;
						if (Name === 'email_verified') attrs['emailVerified'] = Value;
					});

					return {
						...attrs,
						username,
						enabled,
						status,
						created,
						groups
					};
				}
			)
			.filter(
				({ username, givenName, familyName, email }) =>
					username.includes(nameFilter) ||
					givenName.includes(nameFilter) ||
					familyName.includes(nameFilter) ||
					email.includes(nameFilter)
			)
			.sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()));

		return (
			<Row noGutters>
				<Col xs="12">
					<Table>
						<thead>
							<tr>
								<th style={{ width: '60px' }}>#</th>
								<th style={{ width: '100px' }} className="text-center">
									Enabled
								</th>
								<th style={{ width: '250px' }}>Username</th>
								<th style={{ width: '350px' }}>
									Email <sup>(verified)</sup>
								</th>
								<th colSpan="2">
									Groups
									<span
										className="position-absolute align-bottom"
										style={{ right: '12px', top: '10px' }}
									>
										<CustomInput
											id="nameFilter"
											name="nameFilter"
											type="text"
											className="mr-2 name-filter"
											onChange={(event) => this.handleChange(event)}
											defaultValue={nameFilter}
											placeholder="Search"
											bsSize="sm"
											inline
										/>
										<ButtonGroup size="sm" className="mr-2">
											<Button
												color="secondary"
												outline={typeFilter !== 'staging'}
												onClick={() =>
													this.handleChange({
														target: { id: 'typeFilter', value: 'staging' }
													})}
											>
												Staging
											</Button>
											<Button
												color="danger"
												outline={typeFilter !== 'production'}
												onClick={() =>
													this.handleChange({
														target: { id: 'typeFilter', value: 'production' }
													})}
											>
												<i className="fas fa-exclamation mr-2" />Production
											</Button>
										</ButtonGroup>
										<Button size="sm" outline color="secondary" onClick={this.onReloadList}>
											<i className="fas fa-sync-alt" />
											<span className="sr-only">Refresh</span>
										</Button>
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{dspUsers.map((user, ind) => {
								const { username, enabled, email, emailVerified, groups = [] } = user;
								const dspInd = ind + 1;
								return (
									<tr key={`region${dspInd}`}>
										<th scope="row" style={{ width: '60px' }}>
											{dspInd}
										</th>
										<td className="text-center" style={{ width: '100px' }}>
											{enabled && <i className="fas fa-check text-success" />}
											{!enabled && <i className="fas fa-times text-danger" />}
										</td>
										<td style={{ width: '250px' }}>{username}</td>
										<td style={{ width: '400px' }}>
											{email}{' '}
											<sup>
												{emailVerified === 'true' && (
													<i className="fas fa-check-circle text-success" />
												)}
												{emailVerified === 'false' && (
													<i className="fas fa-times-circle text-danger" />
												)}
											</sup>
										</td>
										<td>{groups.join(' | ')}</td>
										<td style={{ width: '45px' }}>
											<UncontrolledDropdown size="sm">
												<DropdownToggle className="pointered btn-light">
													<i className="fas fa-bars" />
													<span className="sr-only">Actions</span>
												</DropdownToggle>
												<DropdownMenu>
													<DropdownItem
														className="pointered"
														onClick={(e) => this.onUserGroups({ user }, e)}
													>
														<i className="fas fa-users fa-fw mr-2" />Groups
													</DropdownItem>
													{enabled && (
														<Fragment>
															<DropdownItem
																className="pointered"
																onClick={(e) => this.onDisableUserAction({ user }, e)}
															>
																<i className="fas fa-pause fa-fw mr-2" />Disable
															</DropdownItem>
															<DropdownItem divider />
															<DropdownItem
																disabled
																className="pointered"
																onClick={(e) => this.onDeleteUser({ user }, e)}
															>
																<i className="fas fa-trash-alt fa-fw mr-2 text-muted" />Delete
															</DropdownItem>
														</Fragment>
													)}
													{!enabled && (
														<Fragment>
															<DropdownItem
																className="pointered"
																onClick={(e) => this.onEnableUserAction({ user }, e)}
															>
																<i className="fas fa-play fa-fw mr-2" />Enable
															</DropdownItem>
															<DropdownItem divider />
															<DropdownItem
																className="pointered"
																onClick={(e) => this.onDeleteUser({ user }, e)}
															>
																<i className="fas fa-trash-alt fa-fw mr-2" />Delete
															</DropdownItem>
														</Fragment>
													)}
												</DropdownMenu>
											</UncontrolledDropdown>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		);
	}

	buildModals() {
		const { deleteModal, deleteUser, groupsModal, groupsUser, addGroup } = this.state;
		const filteredSecurityGroups = (groupsUser.groups
			? config.cognitoSecurityGroups.filter((grp) => !groupsUser.groups.includes(grp))
			: config.cognitoSecurityGroups).sort((a, b) => a > b);

		return (
			<Fragment>
				<Modal isOpen={deleteModal} toggle={this.toggleDelete}>
					<ModalHeader toggle={this.toggleDelete}>
						<i className="fas fa-trash-alt mr-3" />Delete a user
					</ModalHeader>
					<ModalBody>
						<p>Please confirm that you really want to delete user '{deleteUser.username}'?</p>
						<p className="small text-muted">A deleted user cannot be recovered.</p>
						<p className="small text-danger">PROCEED WITH CAUTION!</p>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" size="sm" onClick={this.onDeleteUserAction}>
							Delete user
						</Button>{' '}
						<Button color="secondary" size="sm" onClick={this.toggleDelete}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={groupsModal} toggle={this.toggleGroups}>
					<ModalHeader toggle={this.toggleGroups}>
						<i className="fas fa-users mr-3" />
						User groups for '{groupsUser.username}'
					</ModalHeader>
					<ModalBody>
						{groupsUser.groups && (
							<Table hover size="sm" className="user-groups">
								<tbody>
									{groupsUser.groups.map((group, idx) => {
										return (
											<tr key={`group${idx}`}>
												<td>{group}</td>
												<td className="text-right" style={{ width: '60px' }}>
													<i
														className="far fa-trash-alt fa-fw pointered text-danger"
														onClick={(e) =>
															this.onRemoveUserSecurityGroupAction(
																{ groupsUser, group },
																e
															)}
													>
														<span className="sr-only">Delete group</span>
													</i>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						)}
						{filteredSecurityGroups.length > 0 && (
							<div className="mt-3">
								<Button
									className="text-success mr-1 align-middle float-right"
									disabled={addGroup === ''}
									onClick={(e) =>
										this.onAddUserSecurityGroupAction({ groupsUser, group: addGroup }, e)}
									outline
									size="sm"
								>
									<i className="fas fa-check fa-fw" />
									<span className="sr-only">Save security group create</span>
								</Button>

								<CustomInput
									id="addGroup"
									type="select"
									onChange={(e) => this.handleChange(e)}
									bsSize="sm"
									style={{ width: '90%' }}
									value={addGroup}
								>
									<option value="">Please select a group</option>
									{filteredSecurityGroups.map((grp, idx) => (
										<option value={grp} key={`cogGrg${idx}`}>
											{grp}
										</option>
									))}
								</CustomInput>
							</div>
						)}
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" size="sm" onClick={this.toggleGroups}>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}

	render() {
		const { typeFilter } = this.state;
		const title = typeFilter === 'production' ? 'Users - Production' : 'Users - Staging';

		return (
			<DocumentTitle title="Savant Admin | Users">
				<Container fluid>
					<Crumbs path={[ { title } ]} />
					{this.buildResultsTable()}
					{this.buildModals()}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ usersStaging, usersProduction, userProcess }) => {
	return {
		usersStaging, // redux
		usersProduction, // redux
		userProcess // redux
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchUsersStaging: () => dispatch(fetchUsersStaging()),
	fetchUsersProduction: () => dispatch(fetchUsersProduction()),
	enableUserStaging: (data) => dispatch(enableUserStaging(data)),
	enableUserProduction: (data) => dispatch(enableUserProduction(data)),
	disableUserStaging: (data) => dispatch(disableUserStaging(data)),
	disableUserProduction: (data) => dispatch(disableUserProduction(data)),
	deleteUserStaging: (data) => dispatch(deleteUserStaging(data)),
	deleteUserProduction: (data) => dispatch(deleteUserProduction(data)),
	removeUserSecurityGroupStaging: (data) => dispatch(removeUserSecurityGroupStaging(data)),
	removeUserSecurityGroupProduction: (data) => dispatch(removeUserSecurityGroupProduction(data)),
	addUserSecurityGroupStaging: (data) => dispatch(addUserSecurityGroupStaging(data)),
	addUserSecurityGroupProduction: (data) => dispatch(addUserSecurityGroupProduction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
