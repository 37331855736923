import React, { Fragment } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { SideNav, Footer } from 'containers';

export default ({ component: C, props: cProps, noTemplate = false, insecure = false, groupProtect = [], ...rest }) => {
	const groupGuardAllow = groupProtect.length === 0 || groupProtect.some((r) => cProps.authGroups.includes(r));

	if (!cProps.isAuthenticated && !insecure) {
		console.error('Not logged in; access denied to secured route');

		return (
			<Route {...rest} render={(props) => <Redirect to={{ pathname: '/', state: { from: props.location } }} />} />
		);
	} else if (!groupGuardAllow) {
		console.error('Insufficient group privileges; access denied to protected route');

		return (
			<Route
				{...rest}
				render={(props) => <Redirect to={{ pathname: '/home', state: { from: props.location } }} />}
			/>
		);
	}

	return (
		<Route
			{...rest}
			render={(props) => (
				<Fragment>
					<div id="wrapper">
						{!noTemplate && <SideNav {...props} {...cProps} />}
						<div id="content-wrapper">
							<C {...props} {...cProps} />
							{!noTemplate && <Footer />}
						</div>
					</div>
					<a className="scroll-to-top rounded" href="#page-top">
						<i className="fas fa-angle-up" />
						<span className="sr-only">Scroll to top</span>
					</a>
				</Fragment>
			)}
		/>
	);
};
