import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

import {
	FETCH_PSEUDO_GROUPS,
	FETCH_PSEUDO_GROUPS_ERR,
	CREATE_PSEUDO_GROUP,
	CREATE_PSEUDO_GROUP_ERR,
	UPDATE_PSEUDO_GROUP,
	UPDATE_PSEUDO_GROUP_ERR,
	DELETE_PSEUDO_GROUP,
	DELETE_PSEUDO_GROUP_ERR,
	FETCH_PSEUDO_GROUP_SECURITY_GROUPS,
	FETCH_PSEUDO_GROUP_SECURITY_GROUPS_ERR,
	CREATE_PSEUDO_GROUP_SECURITY_GROUP,
	CREATE_PSEUDO_GROUP_SECURITY_GROUP_ERR,
	UPDATE_PSEUDO_GROUP_SECURITY_GROUP,
	UPDATE_PSEUDO_GROUP_SECURITY_GROUP_ERR,
	DELETE_PSEUDO_GROUP_SECURITY_GROUP,
	DELETE_PSEUDO_GROUP_SECURITY_GROUP_ERR,
	getAuthHeaders
} from 'actions';
import config from 'config';
import { replacer } from 'utils';

export const fetchPseudoGroups = () => async (dispatch) => {
	const request = API.get('savant-admin-api', config.apiEndpoints.pseudoGroups, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_PSEUDO_GROUPS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_PSEUDO_GROUPS_ERR,
				payload: err
			})
	);
};

export const createPseudoGroup = (payload) => async (dispatch) => {
	const request = API.post('savant-admin-api', config.apiEndpoints.createPseudoGroup, {
		headers: await getAuthHeaders(),
		body: payload
	});

	return request.then(
		(response) => {
			dispatch({
				type: CREATE_PSEUDO_GROUP,
				payload: response[0]
			});
			dispatch(fetchPseudoGroups());
			toastr.success('Create Pseudo Group', 'Pseudo group created successfully');
		},
		(err) => {
			dispatch({
				type: CREATE_PSEUDO_GROUP_ERR,
				payload: err
			});
			toastr.error('Create Pseudo Group', 'There was an error creating the pseudo group');
		}
	);
};

export const updatePseudoGroup = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.put(
		'savant-admin-api',
		replacer(config.apiEndpoints.updatePseudoGroup, {
			id
		}),
		{
			headers: await getAuthHeaders(),
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: UPDATE_PSEUDO_GROUP,
				payload: response[0]
			});
			dispatch(fetchPseudoGroups());
			toastr.success('Update Pseudo Group', 'Pseudo group updated successfully');
		},
		(err) => {
			dispatch({
				type: UPDATE_PSEUDO_GROUP_ERR,
				payload: err
			});
			toastr.error('Update Pseudo Group', 'There was an error updating the pseudo group');
		}
	);
};

export const deletePseudoGroup = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.del(
		'savant-admin-api',
		replacer(config.apiEndpoints.deletePseudoGroup, {
			id
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_PSEUDO_GROUP,
				payload: response[0]
			});
			dispatch(fetchPseudoGroups());
			toastr.success('Delete Pseudo Group', 'Pseudo group deleted successfully');
		},
		(err) => {
			dispatch({
				type: DELETE_PSEUDO_GROUP_ERR,
				payload: err
			});
			toastr.error('Delete Pseudo Group', 'There was an error deleting the pseudo group');
		}
	);
};

export const fetchPseudoGroupSecurityGroups = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.get(
		'savant-admin-api',
		replacer(config.apiEndpoints.pseudoGroupSecurityGroups, {
			id
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_PSEUDO_GROUP_SECURITY_GROUPS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_PSEUDO_GROUP_SECURITY_GROUPS_ERR,
				payload: err
			})
	);
};

export const createPseudoGroupSecurityGroup = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.post(
		'savant-admin-api',
		replacer(config.apiEndpoints.createPseudoGroupSecurityGroup, {
			id
		}),
		{
			headers: await getAuthHeaders(),
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: CREATE_PSEUDO_GROUP_SECURITY_GROUP,
				payload: response[0]
			});
			dispatch(fetchPseudoGroupSecurityGroups({ id }));
			toastr.success('Create Security Group', 'Security group created successfully');
		},
		(err) => {
			dispatch({
				type: CREATE_PSEUDO_GROUP_SECURITY_GROUP_ERR,
				payload: err
			});
			toastr.error('Create Security Group', 'There was an error creating the security group');
		}
	);
};

export const updatePseudoGroupSecurityGroup = (payload) => async (dispatch) => {
	const { id, securityGroupId } = payload;

	const request = API.put(
		'savant-admin-api',
		replacer(config.apiEndpoints.updatePseudoGroupSecurityGroup, {
			id,
			securityGroupId
		}),
		{
			headers: await getAuthHeaders(),
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: UPDATE_PSEUDO_GROUP_SECURITY_GROUP,
				payload: response[0]
			});
			dispatch(fetchPseudoGroupSecurityGroups({ id }));
			toastr.success('Update Security Group', 'Security group updated successfully');
		},
		(err) => {
			dispatch({
				type: UPDATE_PSEUDO_GROUP_SECURITY_GROUP_ERR,
				payload: err
			});
			toastr.error('Update Security Group', 'There was an error updating the security group');
		}
	);
};

export const deletePseudoGroupSecurityGroup = (payload) => async (dispatch) => {
	const { id, securityGroupId } = payload;

	const request = API.del(
		'savant-admin-api',
		replacer(config.apiEndpoints.deletePseudoGroupSecurityGroup, {
			id,
			securityGroupId
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_PSEUDO_GROUP_SECURITY_GROUP,
				payload: response[0]
			});
			dispatch(fetchPseudoGroupSecurityGroups({ id }));
			toastr.success('Delete Security Group', 'Security group deleted successfully');
		},
		(err) => {
			dispatch({
				type: DELETE_PSEUDO_GROUP_SECURITY_GROUP_ERR,
				payload: err
			});
			toastr.error('Delete Security Group', 'There was an error deleting the security group');
		}
	);
};
