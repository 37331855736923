import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import { Card, CardHeader, CardBody, Container } from 'reactstrap';

export default class NotFound extends Component {
	render() {
		return (
			<DocumentTitle title="Savant Admin | Page Not Found">
				<BodyClassName className="bg-light">
					<Container fluid>
						<Card className="card-login mx-auto mt-5">
							<CardHeader>
								<i className="fas fa-bolt fa-lg mr-3" />Page not found
							</CardHeader>
							<CardBody>
								<p>
									I'm terribly sorry, but that feature is not currently available. If this is an
									unexpected fault please contact your systems administrator for assistance.
								</p>
								<p className="text-center mb-0">
									<NavLink to="/home">Return to dashboard</NavLink>
								</p>
							</CardBody>
						</Card>
					</Container>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}
