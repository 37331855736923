import React, { Component, Fragment } from 'react';
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { Auth } from 'aws-amplify';

import $ from 'jquery';

import { getAuthenticatedUserAttributes } from 'actions';
import { LoaderButton } from 'components';

export default () => (
	<Button className="dropdown-item pointered" data-toggle="modal" data-target="#userDetailsModal">
		<i className="fas fa-pen-square fa-fw mr-1" /> Update My Details
	</Button>
);

export class UserDetailsChangeElements extends Component {
	state = {
		givenName: '',
		familyName: '',
		email: '',
		oldEmail: '',
		isLoading: false,
		confirmResponse: null,
		confirmationCode: '',
		updateSuccess: false,
		errorMsg: ''
	};

	async componentDidMount() {
		await this.updateUserState();

		$('.toast').toast({
			delay: 2500
		});
	}

	async updateUserState() {
		const { givenName, familyName, email } = await getAuthenticatedUserAttributes();
		await this.setState({
			givenName,
			familyName,
			email,
			oldEmail: email
		});
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
			errorMsg: ''
		});
	};

	validateUserDetailForm() {
		const { givenName, familyName, email } = this.state;
		return givenName.trim().length > 0 && familyName.trim().length > 0 && email.trim().length > 0;
	}

	validateConfirmationForm() {
		const { confirmationCode } = this.state;
		return confirmationCode.trim().length > 0;
	}

	hideModal() {
		$('#userDetailsModal').modal('hide');
	}

	hideModalAndToast() {
		this.hideModal();
		$('#updateDetailsToast').toast('show');
	}

	handleUserDetailsChange = async (event) => {
		event.preventDefault();
		this.setState({
			isLoading: true
		});

		const { givenName: given_name, familyName: family_name, email, oldEmail } = this.state;

		try {
			const confirmResponse = await Auth.currentAuthenticatedUser().then((user) => {
				return Auth.updateUserAttributes(user, {
					given_name,
					family_name,
					email
				});
			});

			if (email === oldEmail) {
				this.updateUserState();
				this.setState({
					isLoading: false,
					errorMsg: ''
				});
				this.hideModalAndToast();
				this.props.updateUserState();
			} else {
				this.setState({
					isLoading: false,
					errorMsg: '',
					confirmResponse
				});
			}
		} catch ({ message }) {
			this.setState({
				isLoading: false,
				errorMsg: message
			});
		}
	};

	handleUserDetailsChangeConfirmation = async (event) => {
		event.preventDefault();
		this.setState({
			isLoading: true
		});

		const { confirmationCode } = this.state;

		try {
			await Auth.verifyCurrentUserAttributeSubmit('email', confirmationCode);
			this.updateUserState();
			this.setState({
				isLoading: false,
				errorMsg: '',
				confirmResponse: null,
				confirmationCode: ''
			});
			this.hideModalAndToast();
			this.props.updateUserState();
		} catch ({ message: errorMsg }) {
			this.setState({
				errorMsg,
				isLoading: false
			});
		}
	};

	handleUserDetailsChangeCancel = async (event) => {
		event.preventDefault();

		await this.updateUserState();
		this.setState({
			isLoading: false,
			errorMsg: '',
			confirmResponse: null,
			confirmationCode: ''
		});
		this.hideModal();
	};

	generateUserDetailsForm() {
		return (
			<Form onSubmit={this.handleUserDetailsChange}>
				<div className="modal-header">
					<h4 className="modal-title" id="userDetailsModalLabel">
						Update Details
					</h4>
					<button
						type="button"
						className="close"
						aria-label="Close"
						onClick={this.handleUserDetailsChangeCancel}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">
					<p>Please update your details. All fields are required.</p>

					<Row>
						<Col>
							<FormGroup>
								<Label for="givenName">First name</Label>
								<Input
									id="givenName"
									placeholder="Enter first name"
									value={this.state.givenName}
									onChange={this.handleChange}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label for="familyName">Last name</Label>
								<Input
									id="familyName"
									placeholder="Enter last name"
									value={this.state.familyName}
									onChange={this.handleChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<FormGroup className="mb-0">
						<Label for="email">Email</Label>
						<Input
							id="email"
							placeholder="Enter email"
							value={this.state.email}
							onChange={this.handleChange}
							valid={this.state.email !== this.state.oldEmail}
						/>
						<FormFeedback valid>
							Changing your email will trigger an additional confirmation step
						</FormFeedback>
					</FormGroup>

					{this.state.errorMsg && (
						<Alert color="warning" className="mt-3 mb-0 pt-2 px-3 small">
							{this.state.errorMsg}
						</Alert>
					)}
				</div>

				<div className="modal-footer">
					<LoaderButton
						color="primary"
						size="sm"
						disabled={!this.validateUserDetailForm()}
						type="submit"
						isLoading={this.state.userDetailsAreChanging}
						text="Update my details"
						loadingText="Details change in progress…"
					/>
					<Button color="secondary" size="sm" onClick={this.handleUserDetailsChangeCancel}>
						Cancel
					</Button>
				</div>
			</Form>
		);
	}

	generateUserDetailsConfirmationForm() {
		return (
			<Form onSubmit={this.handleUserDetailsChangeConfirmation}>
				<div className="modal-header">
					<h4 className="modal-title" id="userDetailsModalLabel">
						Confirm Email Change
					</h4>
					<button
						type="button"
						className="close"
						aria-label="Close"
						onClick={this.handleUserDetailsChangeCancel}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">
					<FormGroup>
						<Label for="confirmationCode">Verification code</Label>
						<span className="help-block small font-italic text-muted ml-3">
							Please check your email for the verification code.
						</span>
						<Input
							id="confirmationCode"
							placeholder="Enter verification code"
							autoFocus
							value={this.state.confirmationCode}
							onChange={this.handleChange}
						/>
					</FormGroup>
					{this.state.errorMsg && (
						<Alert color="warning" className="py-2 px-3">
							{this.state.errorMsg}
						</Alert>
					)}
				</div>
				<div className="modal-footer">
					<LoaderButton
						color="primary"
						disabled={!this.validateConfirmationForm()}
						type="submit"
						isLoading={this.state.isLoading}
						text="Confirm email"
						loadingText="Confirming…"
					/>
				</div>
			</Form>
		);
	}

	generateUserDetailsChangeModal() {
		return (
			<div
				className="modal fade"
				id="userDetailsModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="userDetailsModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						{this.state.confirmResponse === null && this.generateUserDetailsForm()}
						{this.state.confirmResponse !== null &&
							!this.state.updateSuccess &&
							this.generateUserDetailsConfirmationForm()}
					</div>
				</div>
			</div>
		);
	}

	generateUserDetailsChangeToast() {
		return (
			<div id="updateDetailsToast" className="toast" role="alert" aria-live="polite" aria-atomic="true">
				<div className="toast-header">
					<i className="fa fa-fw fa-info mr-2" />
					<strong className="mr-auto">Update Details</strong>
					<button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="toast-body">Your details have been successfully updated.</div>
			</div>
		);
	}

	render() {
		return (
			<Fragment>
				{this.generateUserDetailsChangeModal()}
				{this.generateUserDetailsChangeToast()}
			</Fragment>
		);
	}
}
