export const FETCH_REGIONS = 'fetch_regions';
export const FETCH_REGIONS_ERR = 'fetch_regions_err';
export const CREATE_REGION = 'create_region';
export const CREATE_REGION_ERR = 'create_region_err';
export const UPDATE_REGION = 'update_region';
export const UPDATE_REGION_ERR = 'update_region_err';

export const FETCH_SMELTERS = 'fetch_smelters';
export const FETCH_SMELTERS_ERR = 'fetch_smelters_err';
export const CREATE_SMELTER = 'create_smelter';
export const CREATE_SMELTER_ERR = 'create_smelter_err';
export const UPDATE_SMELTER = 'update_smelter';
export const UPDATE_SMELTER_ERR = 'update_smelter_err';
export const DELETE_SMELTER = 'delete_smelter';
export const DELETE_SMELTER_ERR = 'delete_smelter_err';

export const FETCH_PSEUDO_GROUPS = 'fetch_pseudo_groups';
export const FETCH_PSEUDO_GROUPS_ERR = 'fetch_pseudo_groups_err';
export const CREATE_PSEUDO_GROUP = 'create_pseudo_group';
export const CREATE_PSEUDO_GROUP_ERR = 'create_pseudo_group_err';
export const UPDATE_PSEUDO_GROUP = 'update_pseudo_group';
export const UPDATE_PSEUDO_GROUP_ERR = 'update_pseudo_group_err';
export const DELETE_PSEUDO_GROUP = 'delete_pseudo_group';
export const DELETE_PSEUDO_GROUP_ERR = 'delete_pseudo_group_err';

export const FETCH_PSEUDO_GROUP_SECURITY_GROUPS = 'fetch_pseudo_group_security_groups';
export const FETCH_PSEUDO_GROUP_SECURITY_GROUPS_ERR = 'fetch_pseudo_group_security_groups_err';
export const CREATE_PSEUDO_GROUP_SECURITY_GROUP = 'create_pseudo_group_security_group';
export const CREATE_PSEUDO_GROUP_SECURITY_GROUP_ERR = 'create_pseudo_group_security_group_err';
export const UPDATE_PSEUDO_GROUP_SECURITY_GROUP = 'update_pseudo_group_security_group';
export const UPDATE_PSEUDO_GROUP_SECURITY_GROUP_ERR = 'update_pseudo_group_security_group_err';
export const DELETE_PSEUDO_GROUP_SECURITY_GROUP = 'delete_pseudo_group_security_group';
export const DELETE_PSEUDO_GROUP_SECURITY_GROUP_ERR = 'delete_pseudo_group_security_group_err';

export const FETCH_USERS_STAGING = 'fetch_users_staging';
export const FETCH_USERS_STAGING_ERR = 'fetch_users_staging_err';
export const ENABLE_USER_STAGING = 'enable_user_staging';
export const ENABLE_USER_STAGING_ERR = 'enable_user_staging_err';
export const DISABLE_USER_STAGING = 'disable_user_staging';
export const DISABLE_USER_STAGING_ERR = 'disable_user_staging_err';
export const DELETE_USER_STAGING = 'delete_user_staging';
export const DELETE_USER_STAGING_ERR = 'delete_user_staging_err';
export const REMOVE_USER_FROM_GROUP_STAGING = 'remove_user_from_group_staging';
export const REMOVE_USER_FROM_GROUP_STAGING_ERR = 'remove_user_from_group_staging_err';
export const ADD_USER_TO_GROUP_STAGING = 'add_user_to_group_staging';
export const ADD_USER_TO_GROUP_STAGING_ERR = 'add_user_to_group_staging_err';

export const FETCH_USERS_PRODUCTION = 'fetch_users_production';
export const FETCH_USERS_PRODUCTION_ERR = 'fetch_users_production_err';
export const ENABLE_USER_PRODUCTION = 'enable_user_production';
export const ENABLE_USER_PRODUCTION_ERR = 'enable_user_production_err';
export const DISABLE_USER_PRODUCTION = 'disable_user_production';
export const DISABLE_USER_PRODUCTION_ERR = 'disable_user_production_err';
export const DELETE_USER_PRODUCTION = 'delete_user_production';
export const DELETE_USER_PRODUCTION_ERR = 'delete_user_production_err';
export const REMOVE_USER_FROM_GROUP_PRODUCTION = 'remove_user_from_group_production';
export const REMOVE_USER_FROM_GROUP_PRODUCTION_ERR = 'remove_user_from_group_production_err';
export const ADD_USER_TO_GROUP_PRODUCTION = 'add_user_to_group_production';
export const ADD_USER_TO_GROUP_PRODUCTION_ERR = 'add_user_to_group_production_err';
export const CLEAR_USER_PROCESS = 'clear_user_process';
