import { FETCH_PSEUDO_GROUPS } from 'actions';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_PSEUDO_GROUPS:
			return action.payload;
		default:
			return state;
	}
};
