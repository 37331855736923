const menuConfig = {
	items: [
		{
			path: '/home',
			title: 'Dashboard',
			icon: 'fad fa-tachometer-alt',
			groups: []
		},
		{
			path: '/smelter',
			title: 'Smelters',
			icon: 'fad fa-fire',
			groups: []
		},
		{
			path: '/pseudogroup',
			title: 'Pseudo Groups',
			icon: 'fad fa-layer-group',
			groups: []
		},
		{
			path: '/region',
			title: 'Regions',
			icon: 'fad fa-globe-americas',
			groups: []
		},
		{
			path: '/users',
			title: 'Users',
			icon: 'fad fa-user-friends',
			groups: []
		}
		// 	groups: [ 'basic' ]
	]
};

export default menuConfig;
